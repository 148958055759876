import React, { useState, useEffect } from 'react'
import { Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap'
import '../../styles/editDescriptionComponent.scss'
import HgButtonComponent from './hgButtonComponent'
import { useDispatch } from 'react-redux'
import { editDescription } from '../../actions/publishActions'

const EditDescriptionComponent = (props) => {
    const { modal, toggle, publish_uid, description, documentation_link} = props
    const [ editdescription, setEditDescription ] = useState(description);
    const [ documentationLink, setDocumentationLink ] = useState(documentation_link);
    const [ additionalDocumentationText, setAdditionalDocumentationText ] = useState("");
    const dispatch = useDispatch();

    useEffect(() => {
        setEditDescription(description);
        setDocumentationLink(documentation_link); 
    }, [description, documentation_link]);

    const handleSave = () => {
        // Dispatch an action to update the description and documentation link
        dispatch(editDescription(publish_uid, documentationLink, editdescription, additionalDocumentationText));
        toggle();
    }
  return (
    <div className='edit-description-component'>
        <Modal isOpen={modal} toggle={toggle} size={"md"} className='edit-description-component' {...props}>
            <ModalHeader toggle={toggle}>Edit Description </ModalHeader>
            <ModalBody>
                <p className='heading'>DATASET DESCRIPTION</p>
                 {/* Edit Description Field */}
                <label className='label'>Description</label>
                <textarea className='edit-description' value={editdescription} onChange={(e) => setEditDescription(e.target.value)}/>
                {/* Documentation Link Field */}
                <label className='label'>Documentation Link</label>
                <input type='text' className='edit-feild' value={documentationLink} onChange={(e) => setDocumentationLink(e.target.value)}/>
                {/* Additional Documentation Text Field */}
                <label className='label'> Additional Documentation Text </label>
                <textarea type='text' className='additional-documentation-text-feild' value={additionalDocumentationText} onChange={(e) => setAdditionalDocumentationText(e.target.value)}/>
            </ModalBody>
            <ModalFooter>
                <HgButtonComponent buttonText={"Save"} isPrimary={true} onclickHandler={handleSave}></HgButtonComponent>
            </ModalFooter>
        </Modal>
    </div>
  )
}

export default EditDescriptionComponent