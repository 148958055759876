import { Modal, ModalBody, ModalFooter, ModalHeader } from "reactstrap";

import "../../styles/searchDatasets.scss";
import { Fragment, useEffect, useRef, useState } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import HgButtonComponent from "./hgButtonComponent";
import HgDropdownComponent from "./hgDropdownComponent";
import { IoIosSearch } from "react-icons/io";
import {
  LICENSE_TYPE,
  OPEN_SOURCE_LICENSE_LIST,
  PROPRIETARY_LICENSE_LIST,
  PUBLISH_DATASET_STATUS,
} from "../../config";
import { publishDataset, uploadDataset } from "../../actions/publishActions";
import Box from "@mui/material/Box";
import Skeleton from "@mui/material/Skeleton";
import { Typography } from "@mui/material";
import { v4 as uuid } from "uuid";

/**
 * @constant MODAL_VALUE
 * @description value for different modal to render it conditionally
 */
const MODAL_VALUE = {
  SEARCH: "SEARCH_MODAL",
  LICENSE: "MANAGE_LICENSE_MODAL",
};

const cardSubHeadingStyle = { textAlign: "center", fontSize: "13px" };

function SearchDatasets({
  searchModal,
  setSearchModal,
  publishDataset,
  publishData,
  uploadDataset,
}) {
  const [showDescription, setShowDescription] = useState([]);
  // by default it will popup the Search Modal
  const [modalValue, setModalValue] = useState(MODAL_VALUE.SEARCH);
  // defalut value for license is Open source
  const [selectedLicense, setSelectedLicense] = useState(
    LICENSE_TYPE.OPEN_SOURCE
  );
  const [selectedDatasetId, setSelectedDatasetId] = useState(null);
  const [linceseType, setLinceseType] = useState(null);
  const [updatedDataset, setUpdatedDataset] = useState([]);
  const [inputValue, setInputValue] = useState("");
  // gettin the Table element
  const datasetTable = useRef(null);

  // toggle functionality for description
  const handleDescription = (id) => {
    setShowDescription((prevState) => {
      return prevState.includes(id)
        ? prevState.filter((item) => item !== id)
        : [...prevState, id];
    });
  };

  // get the selecting dataset to publish
  const handleSelection = (selectedDataset) => {
    // check first in updatedDataset state if not available the send the deafult first value;
    const filterDataset = updatedDataset.filter(
      (item) => item.uid === selectedDataset.uid
    );
    if (filterDataset.length) {
      setSelectedDatasetId(filterDataset[0].latest_version[0].version_uid);
    } else {
      setSelectedDatasetId(selectedDataset.latest_version[0].version_uid);
    }
  };

  // set the license tpye
  const handleLicense = (item) => {
    setLinceseType(item.value);
  };

  // dispatch the action with payload to publish dataset
  const handelPublishDataset = () => {
    uploadDataset(selectedDatasetId, PUBLISH_DATASET_STATUS.DRAFT, linceseType);
    handleCancle();
  };

  // store the updatedDataset with chage version
  const handleUpdateVersion = (datasetObj, selectedVersionId) => {
    const filterVersionDataset = datasetObj.latest_version.filter(
      (item) => item.version_uid === selectedVersionId
    );
    setUpdatedDataset((prevState) => {
      if (prevState.length) {
        const existingDatasetIndex = prevState.findIndex(
          (item) => item.uid === datasetObj.uid
        );
        if (existingDatasetIndex !== -1) {
          prevState[existingDatasetIndex] = {
            ...prevState[existingDatasetIndex],
            latest_version: filterVersionDataset,
          };
          return prevState;
        }
      }
      return [
        ...prevState,
        { ...datasetObj, latest_version: filterVersionDataset },
      ];
    });
  };

  // resetting the all values
  const handleCancle = () => {
    setLinceseType(null);
    setSelectedDatasetId(null);
    setModalValue(MODAL_VALUE.SEARCH);
    setSearchModal(false);
  };

  const handleScroll = () => {
    let userScrollHeight =
      datasetTable.current.clientHeight +
      Math.ceil(datasetTable.current.scrollTop);
    let windowBottomHeight = datasetTable.current.scrollHeight;
    if (userScrollHeight === windowBottomHeight) {
      if (publishData.toBePublishDataset.has_next) {
        console.log(publishData.toBePublishDataset.page + 1);
        publishDataset(inputValue, publishData.toBePublishDataset.page + 1);
      }
    }
  };

  useEffect(() => {
    // de-boucing the API call
    const timer = setTimeout(() => {
      if (searchModal) {
        publishDataset(inputValue);
      }
    }, 250);
    return () => {
      clearTimeout(timer);
    };
  }, [searchModal, inputValue]);

  useEffect(() => {
    // hasNext is true the hit the API after reaching the bottom by passing the page param
    let docElem = datasetTable.current;
    if (!docElem) return;
    docElem.addEventListener("scroll", handleScroll);
    return () => docElem.removeEventListener("scroll", handleScroll);
  }, [publishData]);
  return (
    <div>
      <Modal
        className="search-modal"
        isOpen={searchModal}
        size="lg"
        centered={true}
      >
        <ModalHeader>
          {/* changing the modal header according to the MODAL_VALUE */}
          {modalValue === MODAL_VALUE.SEARCH ? (
            <>
              <h3>Select Datasets</h3>
              <p>Basic instructions for attaching Data Product to Published</p>
            </>
          ) : (
            <>
              <h3>Manage License</h3>
              <div className="license-header">
                <span>Selected Dataset</span>
                <span style={{ fontSize: "16px" }}>Ocean Marine</span>
              </div>
            </>
          )}
        </ModalHeader>
        <ModalBody>
          {/* changing the modal body according to the MODAL_VALUE */}
          {modalValue === MODAL_VALUE.SEARCH ? (
            <div className="search-modal-body">
              <p>Choose a Dataset</p>
              <div className="search-input">
                <input
                  type="text"
                  placeholder="Search Dataset"
                  value={inputValue}
                  onChange={(e) => setInputValue(e.target.value)}
                />
                <IoIosSearch
                  className="search-icon"
                  size={22}
                  color="#72777F"
                />
              </div>
              <div className="dataset-table" ref={datasetTable}>
                <table>
                  <thead>
                    <tr>
                      <th>Dataset</th>
                      <th>version</th>
                      <th>Modified</th>
                      <th>Type</th>
                      <th>Description</th>
                    </tr>
                  </thead>
                  <tbody>
                    {/* waiting while data is being fetched and creating the td skeleton  */}
                    {publishData.isLoading ||
                    !Object.keys(publishData?.toBePublishDataset).length
                      ? [1, 2, 3, 4, 5].map((item) => {
                          return (
                            <tr key={uuid()} style={{ border: "none" }}>
                              <td colSpan="5" style={{ padding: "0px" }}>
                                <Box sx={{ width: "700px" }}>
                                  <Typography variant="h3">
                                    <Skeleton animation="wave" />
                                  </Typography>
                                </Box>
                              </td>
                            </tr>
                          );
                        })
                      : publishData?.toBePublishDataset?.results.map((item) => {
                          return (
                            <Fragment key={item.uid}>
                              <tr
                                style={{
                                  borderBottom: `${
                                    showDescription.includes(item.uid)
                                      ? "none"
                                      : "1px solid #72777f"
                                  }`,
                                }}
                              >
                                <td>
                                  <input
                                    type="radio"
                                    onClick={() => handleSelection(item)}
                                    name="dataset-name"
                                  />
                                  {item.name.substring(0, 20) +
                                    `${item.name.length > 19 && "..."}`}
                                </td>
                                <td>
                                  <select
                                    onChange={(e) =>
                                      handleUpdateVersion(item, e.target.value)
                                    }
                                    style={{ border: "none", outline: "none" }}
                                  >
                                    {item.latest_version.map((versionObj) => (
                                      <option
                                        key={versionObj.version_uid}
                                        value={versionObj.version_uid}
                                      >
                                        {`Version ${versionObj.version}`}
                                      </option>
                                    ))}
                                  </select>
                                </td>
                                {/* convert the date into MM/DD/YY */}
                                <td>
                                  {new Date(
                                    item.last_modified_date
                                  ).toLocaleDateString("en-US")}
                                </td>
                                <td>{item.dataset_format}</td>
                                <td onClick={() => handleDescription(item.uid)}>
                                  {showDescription.includes(item.uid)
                                    ? "Hide"
                                    : "View"}
                                </td>
                              </tr>
                              {showDescription.includes(item.uid) && (
                                <tr>
                                  <td colSpan="5">
                                    <div className="dataset-description">
                                      <p>Description :</p>
                                      {item.description}
                                    </div>
                                  </td>
                                </tr>
                              )}
                            </Fragment>
                          );
                        })}
                  </tbody>
                </table>
              </div>
            </div>
          ) : (
            <div className="license-modal-body">
              <div className="modal-body-heading">
                <span>DATASET LINCESE</span>
                <p>Choose the type of license you will use</p>
              </div>
              <div className="license-card-container">
                <div
                  className="license-card"
                  style={{
                    border: `${
                      selectedLicense === LICENSE_TYPE.OPEN_SOURCE
                        ? "2px solid #82A6D9"
                        : "none"
                    }`,
                  }}
                  onClick={() => setSelectedLicense(LICENSE_TYPE.OPEN_SOURCE)}
                >
                  <p>Open Source License</p>
                  <p style={cardSubHeadingStyle}>
                    Choose from available Open Source Licenses
                  </p>
                </div>
                {/* selected license card shows the border */}
                <div
                  className="license-card"
                  style={{
                    border: `${
                      selectedLicense === LICENSE_TYPE.PROPRIETARY
                        ? "2px solid #82A6D9"
                        : "none"
                    }`,
                  }}
                  onClick={() => setSelectedLicense(LICENSE_TYPE.PROPRIETARY)}
                >
                  <p>Proprietary License</p>
                  <p style={cardSubHeadingStyle}>
                    I have a Proprietary License to Upload
                  </p>
                </div>
                <div
                  className="license-card"
                  style={{
                    cursor: "not-allowed",
                    opacity: "0.6",
                  }}
                >
                  <p>No License</p>
                  <p style={cardSubHeadingStyle}>
                    Internal Use Only. License required to publish
                  </p>
                </div>
              </div>
              <div className="license-dropdown">
                <span>Which license to use</span>
                <div className="license-hgdropdown">
                  <HgDropdownComponent
                    placeholder={"Data License"}
                    listItems={
                      selectedLicense === LICENSE_TYPE.OPEN_SOURCE
                        ? OPEN_SOURCE_LICENSE_LIST.slice(1)
                        : PROPRIETARY_LICENSE_LIST
                    }
                    defaultValue={"No License"}
                    listIdentifier={
                      selectedLicense === LICENSE_TYPE.OPEN_SOURCE
                        ? OPEN_SOURCE_LICENSE_LIST
                        : PROPRIETARY_LICENSE_LIST
                    }
                    enableSearch={false}
                    onSelectHandler={(item, listIdentifier) =>
                      handleLicense(item)
                    }
                    // customClass={"manage-license-dropdown"}
                  />
                </div>
                <button className="btn-view-license">View License File</button>
              </div>
            </div>
          )}
        </ModalBody>

        <ModalFooter>
          <HgButtonComponent
            buttonText={"Cancel"}
            isPrimary={false}
            onclickHandler={handleCancle}
          />
          {/* changing the button according to MODAL_VALUe */}
          <div className="btn-sumbit">
            {modalValue === MODAL_VALUE.LICENSE && (
              <button
                className="btn-back"
                onClick={() => setModalValue(MODAL_VALUE.SEARCH)}
              >
                Back
              </button>
            )}
            <button
              disabled={
                modalValue === MODAL_VALUE.SEARCH
                  ? !selectedDatasetId
                  : !linceseType
              }
              onClick={() =>
                modalValue === MODAL_VALUE.SEARCH
                  ? setModalValue(MODAL_VALUE.LICENSE)
                  : handelPublishDataset()
              }
            >
              {modalValue === MODAL_VALUE.SEARCH ? "Next" : "Done"}
            </button>
          </div>
        </ModalFooter>
      </Modal>
    </div>
  );
}

// mapping publish data to props
const mapStateToProps = (state) => {
  return {
    publishData: state.publish,
  };
};

// mapping publish actions to props
const mapDispatchToProps = (dispatch) => {
  return {
    publishDataset: bindActionCreators(publishDataset, dispatch),
    uploadDataset: bindActionCreators(uploadDataset, dispatch),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(SearchDatasets);
