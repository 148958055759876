import React, { useEffect, useState } from 'react';
import { Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import '../../styles/modalComponent.scss';
import '../../styles/datasetColumnDetailsComponent.scss';
import '../../styles/form.scss';
import { parseDatasetSchema } from '../../utils/utils';
import { DATASET_COLUMNS_DETAIL_VIEW_TYPE } from '../../config';
import axios from 'axios';

function DatasetColumnDetailsComponent(props) {
    const { datasetSchema } = props;
    const [viewType, setViewType] = useState(DATASET_COLUMNS_DETAIL_VIEW_TYPE.SAMPLE);
    const [datasetSchemaFile, setDatasetSchemaFile] = useState([]);
    const [columnNames, setColumnNames] = useState([]);

    // useEffect(() => {
    //     if (datasetSchema) {
    //         axios.get(datasetSchema).then(({ data }) => {
    //             setDatasetSchemaFile(data);
    //             console.log(data);
    //         });
    //     }
    //     return () => {
    //         setDatasetSchemaFile([]);
    //     }

    // }, [datasetSchema]);

    useEffect(() => {
        if (datasetSchema) {
            const columnNames = parseDatasetSchema(datasetSchema).map((item) => item['index']);
            setColumnNames(columnNames); // Pass column names to parent or another component
        }
    }, [datasetSchema]);



    return (
        <ul>
            <li className='col-header'>
                <span>columns</span>

                <span className='col-actions'>
                    <span className={viewType === DATASET_COLUMNS_DETAIL_VIEW_TYPE.SAMPLE ? 'active' : ''} onClick={() => setViewType(DATASET_COLUMNS_DETAIL_VIEW_TYPE.SAMPLE)}>sample</span>
                    <span className={viewType === DATASET_COLUMNS_DETAIL_VIEW_TYPE.TYPE ? 'active' : ''} onClick={() => setViewType(DATASET_COLUMNS_DETAIL_VIEW_TYPE.TYPE)}>/type</span>
                </span>
            </li>
            {datasetSchema && parseDatasetSchema(datasetSchema).map((item, index) => {
                return (<li key={index}>
                    <span className='col-name'>{item['index']}</span>
                    {viewType === DATASET_COLUMNS_DETAIL_VIEW_TYPE.SAMPLE
                        ? <span className='col-type'>{item['sample_value']}</span>
                        : <span className='col-type'>{item['dtypes']}</span>}
                </li>)
            })}
        </ul>
    );
}

export default DatasetColumnDetailsComponent;