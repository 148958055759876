import React, { useState } from 'react'
import '../../styles/publishedActionsComponent.scss'
import { useHistory } from 'react-router-dom/cjs/react-router-dom';

const PublishedActionsComponent = () => {
    const [activeButton, setActiveButton] = useState();
    const history = useHistory();

    const handleButtonClick = (action) => {
        setActiveButton(action);
        
        // Navigate to the product-preview route if the action is "Preview"
        if(action === "Preview"){
            history.push("/product-preview")
        }
    };

    return (
        <div className='published-actions-component'>
            <div className='published-actions'>
                <div className='published'>Published</div>
                <div className='actions'>
                    <div className='action'>Actions</div>
                    <div className='all-actions'>
                        {["Remove", "Cancel Edits", "Preview", "Save to Drafts"].map(
                            (action) => (
                                <button
                                    key={action}
                                    className={`action-btn ${activeButton === action ? "active" : ""
                                        }`}
                                    onClick={() => handleButtonClick(action)}
                                >
                                    {action}
                                </button>
                            )
                        )}
                        <button
                            className={`publish-btn ${activeButton === "Publish" ? "active" : ""}`}
                            onClick={() => handleButtonClick("Publish")}
                        >
                            Publish
                        </button>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default PublishedActionsComponent