import React, { useState, useEffect } from 'react';
import axios from "axios";
import sessionUtils from '../../utils/sessionUtils';
import { Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
// import '../../styles/modalComponent.scss';
import '../../styles/tagEditModal.scss'
import { getVersionString } from '../../utils/utils';
import DatasetPreviewComponent from './datasetPreviewComponent';
import HgButtonComponent from './hgButtonComponent';
import SafeHtmlComponent from './safeHtmlComponent';
import {Button} from "@mui/material"
import TextField from "@mui/material/TextField";
import styled from "styled-components";
import Switch from '@mui/material/Switch';
import MultiSelect from './MultiSelect';
const { REACT_APP_API_URL } = process.env;
export const API_URL = REACT_APP_API_URL;


const CustomTextField = styled(TextField)({
    marginLeft:'50px',
    display: "flex",
    height: "200px",
    width: "100%",
    gap: "10px",
    alignSelf: "stretch",
    borderRadius: "8px",
    border: "1px dashed #51606F",
    backgroundColor: "#FCFCFF",
    boxShadow:
      "0px 1px 2px 0px rgba(0, 0, 0, 0.30), 0px 1px 3px 1px rgba(0, 0, 0, 0.15)",
    "& .MuiInputBase-root": {
      height: "100%",
      padding: "0",
    },
    "& .MuiInputBase-input": {
      padding: "0 15px",
      fontFamily: "SpaceGrotesk",
      fontSize: "14px",
      fontWeight: 500,
      lineHeight: "20px",
    },
  });

  const label = { inputProps: { 'aria-label': 'Switch demo' } };

  

function TagEditPopupComponent(props) {
  const { tags, toggle,  modal, onAccept, name, version, existingTags, dataset_root_uid, dataset_child_uid} = props
    const [isToggled, setIsToggled] = useState(false);
    const [selectedKeyword, setSelectedKeyword] = useState({selectedKeyword:[]});
    const handleToggle = (event) => {
        setIsToggled(event.target.checked);
      };

      useEffect(() => {
        setSelectedKeyword({...selectedKeyword, selectedKeyword:existingTags}); // Set selectedKeyword to existingTags
      }, [existingTags]);

const handleSave = async () => {
  
  const requestBody = {
      dataset_root_uid: dataset_root_uid,
      dataset_child_uid: dataset_child_uid,
      apply_to_all_versions: isToggled,
      tags: selectedKeyword.selectedKeyword
      
  };

  const token = sessionUtils.getToken("access_token");
  
  const headers = {
    'Authorization': `Bearer ${token}`, // Replace with your actual token
    'Content-Type': 'application/json',
};

  try {
      const response = await axios.put(
          `${API_URL}/auth/v1/update-dataset/tags/`,
          requestBody,
          {headers}
      );
      if (response.status === 200) {
        // onSave(editableLatitude, editableLongitude, isToggled);
        
          onAccept(); // Trigger callback if provided
          
          // toggle(); // Close the modal
        
      }
  } catch (error) {
      console.error("Error updating tag data:", error);
      alert("Failed to update tags. Please try again.");
  }
};


    return (
        <div className='tag-edit-component'>
            <Modal style={{width:'800px'}} isOpen={modal} toggle={toggle} size={"md"} className={"tag-edit-component"}  {...props}>
                <ModalHeader toggle={toggle}>Edit Tags
                </ModalHeader>
                <ModalBody>

                <div className="content">
                    <div className="label-text-2">Dataset Title</div>
                  <div className="div-wrapper">
                    <p className="input-text-2">{name}</p>
                  </div>
                </div>

                <div className="content">
                    <div className="label-text-2">Dataset Version</div>
                  <div className="div-wrapper">
                    <p className="input-text-2">{version}</p>
                  </div>
                </div>



                <div className="content">
                    <div className="label-text-3">Edit Tags</div>
                </div>

                
                <div className="div-wrapper100">
              <div className="label-text-201">Tags</div>
            </div>
            <div className='customtags' >
     <MultiSelect style={{width:'600px'}}
                selectedKeyword={selectedKeyword.selectedKeyword}
                setSelectedKeyword={setSelectedKeyword}
              />
</div>


                    {/* <div style={{wordWrap:'break-word', marginLeft:'46px'}} className='pd-8'><SafeHtmlComponent data={description} /></div> */}
                </ModalBody>
                <ModalFooter>
                    <div className='togglebtn'>   
                <div
                style={{display:"flex", alignItems:'center'}} >
      <Switch
        checked={isToggled}
        onChange={handleToggle}
        color="primary" // 'primary', 'secondary', or 'default'
      />
      <div variant="body1">
        {isToggled ? "Changes applied to all the versions" : "Apply changes to all versions"}
      </div>
    
    </div>
                    {/* <HgButtonComponent buttonText={"Close"} isPrimary={false} onclickHandler={() => onAccept()} /> */}
                    <Button
                    className='btnspecial'
                variant="outlined"
                onClick={handleSave}
              >
                SAVE
              </Button>

              </div>
                </ModalFooter>
            </Modal>
            </div>
    );
}

export default TagEditPopupComponent;