import React, { useState } from 'react'
import { Button, Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap'
import '../../styles/editBasicInfoPopupComponent.scss'
import HgButtonComponent from './hgButtonComponent'
import PillButton from './pillButton'

const EditBasicInfoPopupComponent = (props) => {
    const { modal, toggle, setIsProductPricingModalOpen } = props

    const [inputValue, setInputValue] = useState("");
    const [keywords, setKeywords] = useState([]);

    const handleInputChange = (e) => {
        setInputValue(e.target.value);
    };

    const handleKeyDown = (e) => {
        if (e.key === "Enter" && inputValue.trim()) {
            const newKeyword = [...keywords, inputValue.trim()];
            setKeywords(newKeyword);
            setInputValue("");
        }
    };

    const removeKeyword = (keyword) => {
        const updatedKeywords = keywords.filter((k) => k !== keyword);
        setKeywords(updatedKeywords);
    };

    const handlEditPricing = () => {
        toggle();
        setIsProductPricingModalOpen(true);
    }

    return (
        <div className='edit-basic-info-component'>
            <Modal isOpen={modal} toggle={toggle} size={"md"} className='edit-basic-info-component' {...props}>
                <ModalHeader>Basic Information</ModalHeader>
                <ModalBody>
                    <div className='headings'>Title</div>
                    <input type='text' className='input-feilds' />
                    <div className='headings'>Pricing</div>
                    <div className='pricing'>
                        <label className='type'>TYPE</label>
                        <div className='paid'>PAID</div>
                        <label className='type'>PRICE</label>
                        <div className='paid'>99.99</div>
                        <label className='type'>BILLING</label>
                        <div className='paid'>MONTHLY</div>
                    </div>
                    <Button className="edit-pricing" style={{ cursor: "pointer" }} onClick={() => handlEditPricing()}>
                        Edit Pricing
                    </Button>
                    <div className='headings'>Publisher</div>
                    <input type='text' className='input-feilds' />
                    <div className='headings'>Attribution</div>
                    <input type='text' className='input-feilds' />
                    <div className='headings'>Subtitle</div>
                    <input type='text' className='input-feilds' />
                    <div className='headings'>Tags</div>
                    <div className="pill-list">
                        {keywords.map((keyword, index) => (
                            <PillButton
                                key={index}
                                keyword={keyword}
                                removeKeyword={() => removeKeyword(keyword)}
                            />
                        ))}
                        <input
                            type="text"
                            className='input-feilds'
                            value={inputValue}
                            onChange={handleInputChange}
                            onKeyDown={handleKeyDown}
                        />
                    </div>
                </ModalBody>
                <ModalFooter>
                    <HgButtonComponent buttonText={"Cancel"} isPrimary={false} onclickHandler={toggle} />
                    <HgButtonComponent buttonText={"Save"} isPrimary={true} onclickHandler={toggle} />
                </ModalFooter>
            </Modal>
        </div>
    )
}

export default EditBasicInfoPopupComponent