import React, { useState } from 'react'
import { Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap'
import HgButtonComponent from './hgButtonComponent'
import '../../styles/productPricingComponent.scss'
import Switch from '@mui/material/Switch';


const ProductPricingComponent = (props) => {
  const { modal, toggle } = props
  const [selectedCard, setSelectedCard] = useState("oneTimePayment");
  return (
    <div className='product-pricing-component'>
      <Modal isOpen={modal} toggle={toggle} size={"md"} {...props} className='product-pricing-component'>
        <ModalHeader>
          <h3>Product Pricing</h3>
          <p>Basic instructions for creating a listing for Ocean of Data Publishing</p>
        </ModalHeader>
        <ModalBody>
          <div className='title'>Product Title</div>
          <div className='product'>Product Discovery</div>
          <div className='cards'>
            {/* One-Time Payment Card */}
            <div className={`payment ${selectedCard === "oneTimePayment" ? "oneTimePayment" : ""}`} onClick={() => setSelectedCard("oneTimePayment")}>
              <p className='card-title'>One Time Payment</p>
              <p>One time customer payment
                Terms Apply</p>
            </div>
            {/* Subscription Payment Card */}
            <div className={`payment ${selectedCard === "subscription" ? "subscription" : ""}`} onClick={() => setSelectedCard("subscription")}>
              <p className='card-title'>Subscription</p>
              <p className='subscription'>Service is billed
                monthly or yearly
                Terms Apply</p>
            </div>
          </div>
          <div className='product'>Product Cost</div>
          <input type='text' className='edit-cost' />
           {/* Conditional Rendering Based on Selected Payment Option */}
          {selectedCard === "oneTimePayment" ? (
            <div className='product'>One Time Purchase</div>
          ) : (<>
            <p className='product'>Monthly Subscription Price</p>
            <input type='text' className='edit-cost' />
            <p className='product'>Yearly Subscription Price</p>
            <div className='toggle'>
              <Switch sx={{
                transform: 'scale(1.5)',
                '& .MuiSwitch-thumb': {
                  width: 18,
                  height: 18,
                },
                '& .MuiSwitch-track': {
                  height: 14,
                },
              }} />
              <p>Set Yearly Subcription at 20%  Discount  of Monthly</p>
            </div>
          </>)}


          <label className='review'>Review Terms</label>
        </ModalBody>
        <ModalFooter>
          <HgButtonComponent buttonText={"Cancel"} isPrimary={false} onclickHandler={toggle} />
          <HgButtonComponent buttonText={"NEXT"} isPrimary={true} onclickHandler={toggle} />
        </ModalFooter>
      </Modal>
    </div>
  )
}


export default ProductPricingComponent
