import { useState, useEffect } from "react";
import SearchBarComponent from "./pure/searchBarComponent";
import "../styles/publish.scss";
import Table from "./pure/tableComponent";
import { getPublishDatasetsList } from "../actions/publishActions"
import { useDispatch, useSelector } from "react-redux";
import { Pagination } from "@mui/material";

function Publish() {
  const [searchText, setSearchText] = useState();
  const [page, setPage] = useState(1);
  const publishHeader = [
    { lable: "Title", sortable: true },
    { lable: "Status", sortable: true },
    { lable: "Owner", sortable: true },
    { lable: "Pricing", sortable: true },
    { lable: "Last Action", sortable: true },
    { lable: "Actions", sortable: false },
  ];

  const dispatch = useDispatch();

  const { publishedDatasets, isLoading } = useSelector((state) => ({
    publishedDatasets: state.publish.publishedDatasets,
    isLoading: state.publish.isLoading,
  }));

  useEffect(() => {
    // dispatch getPublishDatasetsList action to fetch the datasets for the current page
    dispatch(getPublishDatasetsList(page));
  }, [page]);

  const handleChange = (event, value) => {
    setPage(value);
  };

  return (
    <div>
      <div className="pulish-filters">
        <SearchBarComponent setSearchText={setSearchText} />
        <div className="filter-dropdown">
          <label htmlFor="status">Status</label>
          <select id="status">
            <option value={""}>All</option>
            <option value={"Published"}>Published</option>
            <option value={"Draft"}>Draft</option>
            <option value={"Unpublished"}>Unpublished</option>
          </select>
        </div>
      </div>
      {/* table component accpet Array of table head and table data as props */}
      <Table tableHeader={publishHeader} tableData={publishedDatasets.results} isLoading={isLoading} />
      <div className="pagination">
      <Pagination
        count={Math.ceil(publishedDatasets.total_dataset / publishedDatasets.page_size)}
        color="primary"
        shape="rounded"
        page={page}
        siblingCount={3}
        boundaryCount={1}
        onChange={handleChange}
      />
      </div>
    </div>
  );
}

export default Publish;
