import React, { useEffect, useState } from 'react'
import HeaderComponent from './headerComponent'
import SidebarComponent from './sidebarComponent'
import { SIDEBAR_TABS } from '../../config'
import '../../styles/publishedDatasetsComponent.scss'
import KeyboardArrowLeftIcon from "@mui/icons-material/KeyboardArrowLeft";
import PublishedActionsComponent from './publishedActionsComponent'
import CardComponent from './cardComponent'
import { Link, useParams } from 'react-router-dom/cjs/react-router-dom.min'
import EditBasicInfoPopupComponent from './editBasicInfoPopupComponent'
import ProductPricingComponent from './productPricingComponent'
import EditDescriptionComponent from './editDescriptionComponent'
import { getPublishedDatasetDetails } from '../../actions/publishActions'
import { useDispatch, useSelector } from 'react-redux'

const PublishedDatasetsComponent = () => {
  // Extracting dataset `uid` from the route parameters
  const { uid } = useParams();
   // State to manage the currently active modal
  const [activeModal, setActiveModal] = useState(null);
  // State to manage the visibility of the Product Pricing modal
  const [isProductPricingModalOpen, setIsProductPricingModalOpen] = useState(false);
  const dispatch = useDispatch();

  // accessing the dataset details from the store
  const { publishedDatasetDetails } = useSelector((state) => ({
    publishedDatasetDetails: state.publish.publishedDatasetDetails.data,
  }))

  // Fetch dataset details when the component mounts
  useEffect(() => {
    if (uid) {
      dispatch(getPublishedDatasetDetails(uid));
    }
  }, []);

  // Fields for the Dataset section
  const datasetFeilds = [
    { label: "Dataset", value: publishedDatasetDetails?.data?.title, linkText: "view dataset", },
    { label: "Version", value: "Version 2" },
    { label: "License", value: publishedDatasetDetails?.data?.license, linkText: "view license" },
    { label: "Dataset Sample", value: "sampledata.xyz" },
  ];

  // Fields for the Basic Information section
  const basicInfoFields = [
    { label: "Title", value: publishedDatasetDetails?.data?.title },
    { label: "Type", value: publishedDatasetDetails?.data?.price_type || "Free" },
    { label: "Publisher", value: publishedDatasetDetails?.data?.publisher || "NA" },
    { label: "Attribution", value: "NASA" },
    { label: "Subtitle", value: publishedDatasetDetails?.data?.subtitle || "NA" },
    { label: "Tags", value: publishedDatasetDetails?.data?.tags?.join(", ") || "NA"},
    { label: "Pricing", value: `Type:${ publishedDatasetDetails?.data?.subcription_type || "NA" } Cost : ${publishedDatasetDetails?.data?.product_cost || "NA"}` },
  ];

   // Fields for the Description section
  const descriptionFeilds = [
    { label: "Description", value: publishedDatasetDetails?.data?.description || "NA" },
    { label: "Documentation", linkText: publishedDatasetDetails?.data?.documentation_link || "NA" }
  ];

  // Fields for the Additional Information section
  const additionalFields = [
    { label: "Contact Information?", value: publishedDatasetDetails?.data?.contact_information || "NA" },
    { label: "Ocean of Data Listing", linkText: publishedDatasetDetails?.data?.ocean_of_data_url || "NA" },
    { label: "FAQ", linkText: publishedDatasetDetails?.data?.faq || "NA" },
    { label: "Terms", linkText:publishedDatasetDetails?.data?.terms || "NA" },
  ];

  // function to open a modal by name
  const handleOpenModal = (modalName) => {
    setActiveModal(modalName);
  };

    // function to close the currently active modal
  const handleCloseModal = () => {
    setActiveModal(null);
  };

   // function to toggle the visibility of the product pricing modal
  const handlePricingModalToggle = () => {
    setIsProductPricingModalOpen(!isProductPricingModalOpen);
    setActiveModal("editBasicInfo");
  };

  return (
    <div className='published-datasets-component'>
      <HeaderComponent />
      <SidebarComponent activeTab={SIDEBAR_TABS.publishingManager} />
      <div className='main'>
        <p className="publishing-heading">Ocean of Data Publishing/ Published Datasets/ Product...</p>
        <div className="published-datasets">
          <KeyboardArrowLeftIcon />
          <Link to={"/publishing-manager"}>Published Datasets</Link>
        </div>
        <PublishedActionsComponent />
        <CardComponent title="Dataset" feilds={datasetFeilds} />
        <CardComponent title="Basic Information" feilds={basicInfoFields} onEdit={() => handleOpenModal("editBasicInfo")} />
        <CardComponent title="Description" feilds={descriptionFeilds} onEdit={() => handleOpenModal("editDescription")} />
        <CardComponent title="Additional" feilds={additionalFields} />

        {/* modal for editing basic information */}
        <EditBasicInfoPopupComponent
          modal={activeModal === "editBasicInfo" ? true : false}
          toggle={handleCloseModal}
          openProductPricing={() => handleOpenModal("productPricing")}
          setIsProductPricingModalOpen={setIsProductPricingModalOpen}
        />

        {/* modal for edit product price */}
        <ProductPricingComponent
          modal={isProductPricingModalOpen}
          toggle={handlePricingModalToggle}
        />

        {/* modal for edit description */}
        <EditDescriptionComponent
          modal={activeModal === "editDescription" ? true : false}
          toggle={handleCloseModal}
          publish_uid = {uid}
          description= {publishedDatasetDetails?.data?.description}
          documentation_link= {publishedDatasetDetails?.data?.documentation_link}
        />
      </div>
    </div>
  )
}

export default PublishedDatasetsComponent