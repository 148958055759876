import { Modal, ModalBody, ModalFooter, ModalHeader } from "reactstrap";
import "../styles/viewVersionsPopup.scss";
import React, { useEffect, useState } from "react";
import "../styles/manageVersions.scss";
import Switch from "@mui/material/Switch";
import { styled } from "@mui/material/styles";
import axios from "axios";
import { API_URL } from "../config";
import SessionUtils from "../utils/sessionUtils";
import { formatDate } from "../utils/utils";
import { useParams } from "react-router-dom/cjs/react-router-dom.min";
import HgButtonComponent from "./pure/hgButtonComponent";
const ViewVersionsPopupComponent = (props) => {
  const label = { inputProps: { "aria-label": "Switch demo" } };
  const [datasetVersionListing, setDatasetVersionListing] = useState([]);
  const [activeIndex, setActiveIndex] = useState(null);
  const [activeDeleteIndex, setActiveDeleteIndex] = useState(null); 
  const [newInputValue, setNewInputValue] = useState("");
  const [buttonChecked, setButtonChecked] = useState(false);
  const { uid } = useParams();
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  const [versionNameUpdateList, setVersionNameUpdateList] = useState([]);

  const giveDefaultVersionNaming = () => {
    let newListing = datasetVersionListing.map((item, index) => {
      if (item.version === null) {
        return { ...item, version: index + 1 };
      } else return item;
    });
    setDatasetVersionListing(newListing);
  };

 const deleteVersion = (versionId) =>{
    let newListing = datasetVersionListing.filter((item) => item.version_uid !== versionId);
    setDatasetVersionListing(newListing)
    closeDeleteModal()
 }

 const closeDeleteModal = ()=>{
  setIsDeleteModalOpen(false)
 }
  const IOSSwitch = styled((props) => (
    <Switch
      focusVisibleClassName=".Mui-focusVisible"
      disableRipple
      checked={buttonChecked}
      onChange={(event) => {
        if (event.target.checked) {
          setButtonChecked(true);
          giveDefaultVersionNaming();
        } else if (event.target.checked === false) {
          setButtonChecked(false);
        }
      }}
      {...props}
    />
  ))(({ theme }) => ({
    width: 50,
    height: 26,
    padding: 0,
    "& .MuiSwitch-switchBase": {
      padding: 0,
      margin: 2,
      transitionDuration: "300ms",
      "&.Mui-checked": {
        transform: "translateX(16px)",
        color: "#fff",
        "& + .MuiSwitch-track": {
          backgroundColor: "rgba(0, 99, 154, 1)",
          opacity: 1,
          border: 0,
          ...theme.applyStyles("dark", {
            backgroundColor: "rgba(0, 99, 154, 1)",
          }),
        },
        "&.Mui-disabled + .MuiSwitch-track": {
          opacity: 0.5,
        },
      },
      "&.Mui-focusVisible .MuiSwitch-thumb": {
        color: "#33cf4d",
        border: "6px solid #fff",
      },
      "&.Mui-disabled .MuiSwitch-thumb": {
        color: theme.palette.grey[100],
        ...theme.applyStyles("dark", {
          color: theme.palette.grey[600],
        }),
      },
      "&.Mui-disabled + .MuiSwitch-track": {
        opacity: 0.7,
        ...theme.applyStyles("dark", {
          opacity: 0.3,
        }),
      },
    },
    "& .MuiSwitch-thumb": {
      boxSizing: "border-box",
      width: 22,
      height: 22,
    },
    "& .MuiSwitch-track": {
      borderRadius: 26 / 2,
      backgroundColor: "#E9E9EA",
      opacity: 1,
      transition: theme.transitions.create(["background-color"], {
        duration: 500,
      }),
      ...theme.applyStyles("dark", {
        backgroundColor: "#39393D",
      }),
    },
  }));
 
  useEffect(() => {
     getLatestListing()
  }, []);



  const addVersionCopy = (version) => {
    const data = {
      child_dataset_id:version.version_uid
    }
    const token = SessionUtils.getToken("access_token");

    const headers = {
      Authorization: `Bearer ${token}`, // Replace with your actual token
      "Content-Type": "application/json",
    };
    axios.put(`${API_URL}/auth/v1/copy-version/`,data,{headers}).then((copyResponse)=>{
      if(copyResponse.status === 200){
        getLatestListing()
      }
    }).catch((error)=>{
      console.log(error)
    });
    
    
   
  };
  const editVersionName = (versionId) => {
    const newListing = datasetVersionListing.map((currItem) => {
      if (currItem.version_uid === versionId) {
        const newItem = { ...currItem, version: newInputValue };
        setVersionNameUpdateList([...versionNameUpdateList,{
          dataset_child_uid: versionId,
          version: newInputValue
        }])
        setNewInputValue("");
        return newItem;
      } else return currItem;
    });

    setDatasetVersionListing(newListing);
  };
  const getLatestListing = async()=>{
    const token = SessionUtils.getToken("access_token");

    const headers = {
      Authorization: `Bearer ${token}`, // Replace with your actual token
      "Content-Type": "application/json",
    };

    try {
      const versionListingResponse = await axios.get(
        `${API_URL}/auth/v1/dataset-version/list/?root_dataset_id=${uid}`,
        { headers }
      );
     
      if (versionListingResponse.status === 200) {
        setDatasetVersionListing(versionListingResponse.data.response);
      }
    } catch (error) {
      console.log("Error fetching dataset versions", error);
    }
  }
  const onSave = ()=>{
    const token = SessionUtils.getToken("access_token");
    const headers = {
      Authorization: `Bearer ${token}`, // Replace with your actual token
      "Content-Type": "application/json",
    };
    axios.put(`${API_URL}/auth/v1/update-dataset/version/`,versionNameUpdateList,{headers}).then((response)=>{
      if(response.status === 200){
        props.toggleVersionModal()
      }
    }).catch((error)=>{
      console.log(error)
    })
  }
  return (
    <div className="version-popup-container">
      <Modal
        className="manage-versions-modal"
        isOpen={props.versionOpen}
        toggle={props.toggleVersionModal}
      >
        <div className="manage-versions-container">
          <h2>Manage Versions</h2>
          <div className="manage-versions-desc">
            <p>
              Following datasets are processing in the background. Please do not
              close or exit your browser window to ensure the upload completes
              successfully.
            </p>
          </div>
          <div className="manage-versions-upload-section">
            <button>Upload New Version</button>
          </div>

          {/* Version 1 */}
         <div className="versions-container">
         {datasetVersionListing.map((currItem, idx) => {
            return (
              <div className="manage-versions-version-item" key={idx}>
                <div className="manage-versions-version-info">
                  <div className="manage-versions-info-divs">
                    <p className="manage-versions-info-titles">Version</p>
                    <p className="manage-versions-info-details">
                      {currItem.version}
                    </p>
                  </div>
                  <div className="manage-versions-info-divs">
                    <p className="manage-versions-info-titles">Uploaded</p>
                    <p className="manage-versions-info-details">
                      {" "}
                      {formatDate(currItem.upload_date)}
                    </p>
                  </div>
                  <div className="manage-versions-version-actions">
                    <p className="manage-versions-info-titles">Actions</p>
                    <div className="manage-versions-action-buttons-container">
                      <button
                        onClick={() =>
                          setActiveIndex(activeIndex !== idx ? idx : null)
                        }
                        disabled = {buttonChecked ? true : false}
                      >
                        Rename Version
                      </button>
                      <button onClick={() => addVersionCopy(currItem)}>
                        Create Copy
                      </button>
                      <button onClick={()=>{
                        setActiveDeleteIndex(idx)
                        setIsDeleteModalOpen(true)
                        }} className="manage-versions-delete-button">
                        Delete
                      </button>
                     { activeDeleteIndex === idx && <Modal
                        style={{
                          width: "fit-content",
                          top: "24px",
                          left: "20px",
                          padding: "16px",
                        }}
                        isOpen={isDeleteModalOpen}
                        onRequestClose={()=>closeDeleteModal()}
                        size={"md"}
                        className={"share-dataset-component"}
                      >
                        <ModalHeader
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                            alignItems: "center",
                            height: "32px",
                          }}
                        >
                          <span
                            style={{
                              flex: "2",
                              fontSize: "11px",
                              fontWeight: 500,
                              lineHeight: "16px",
                            }}
                          >
                            Delete Confirmation
                          </span>
                          {/* <img src="/static/media/cross-rounded.c7f43de0.svg"  style={{ cursor: 'pointer' }} onClick={this.closeDeleteModal}></img> */}

                          {/* <RxCross1 style={{ cursor: 'pointer',  marginLeft: '220px', color: 'grey', fontSize: '20px' }} onClick={this.closeDeleteModal} onMouseOver={(e) => { e.target.style.color = 'black'; }} // Change color to white on hover
                      onMouseOut={(e) => { e.target.style.color = 'grey'; }} /> */}
                        </ModalHeader>
                        <ModalBody>
                          <div
                            className="gen-info"
                            style={{
                              width: "100%",
                              fontSize: "16px",
                              lineHeight: "24px",
                              height: "57px",
                              color: "#1A1C1E",
                            }}
                          >
                            Are you sure you want to delete the Selected
                            Datasets?
                          </div>

                          <div
                            style={{
                              fontSize: "11px",
                              lineHeight: "24px",
                              color: "#93000A",
                              fontWeight: 500,
                              lineHeight: "16px",
                              height: "16px",
                            }}
                          >
                            This action cannot be undone.
                          </div>
                        </ModalBody>
                        <ModalFooter style={{ padding: "10px" }}>
                          <HgButtonComponent
                            buttonText={"CANCEL"}
                            isPrimary={false}
                            onclickHandler={()=>closeDeleteModal()}
                          />
                          <HgButtonComponent
                            buttonText={"DELETE"}
                            isPrimary={true}
                            onclickHandler={ ()=>deleteVersion(currItem.version_uid) }
                            type="submit"
                          />
                        </ModalFooter>
                      </Modal>}
                    </div>
                  </div>
                </div>
                {activeIndex === idx && (
                  <div className="manage-versions-rename-section">
                    <label>Edit Name</label>
                    <div>
                      <input
                        type="text"
                        value={newInputValue}
                        onChange={(e) => setNewInputValue(e.target.value)}
                      />
                      <button
                      disabled = {newInputValue === "" ? true : false}
                        onClick={() => {
                          editVersionName(currItem.version_uid);
                          setActiveIndex(activeIndex !== idx ? idx : null);
                        }}
                      >
                        Apply
                      </button>
                    </div>
                  </div>
                )}
              </div>
            );
          })}
         </div>
         

          {/* Toggle Section */}
          <div className="manage-versions-toggle-container">
            <div className="manage-versions-toggle-section">
              <label>
                <IOSSwitch />
                Automatically Managed Version Naming
              </label>
            </div>

            {/* Save Buttons */}
            <div className="manage-versions-save-buttons">
              <button
                className="manage-versions-cancel-btn"
                onClick={props.toggleVersionModal}
              >
                Cancel
              </button>
              <button className="manage-versions-save-btn" onClick={()=>onSave()}>Save</button>
            </div>
          </div>
        </div>
      </Modal>
    </div>
  );
};

export default ViewVersionsPopupComponent;
