import React, { useState } from 'react';
import { Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
// import '../../styles/modalComponent.scss';
import '../../styles/datasetDescriptionModal.scss'
import { getVersionString } from '../../utils/utils';
import DatasetPreviewComponent from './datasetPreviewComponent';
import HgButtonComponent from './hgButtonComponent';
import SafeHtmlComponent from './safeHtmlComponent';
import {Button} from "@mui/material"

function DatasetDescriptionPopupComponent(props) {
    const { description, toggle,  modal, onAccept, name, version, toggle1} = props

    const handleEditClick = () => {
      // Close the current modal (toggle)
      toggle();
      // Open the edit modal (toggle1)
      toggle1();
  };

    return (
        <div className='view-dataset-description-component'>
            <Modal isOpen={modal} toggle={toggle} size={"md"} className={"view-dataset-description-component"} {...props}>
                <ModalHeader toggle={toggle}>Description</ModalHeader>
                <ModalBody>

                <div className="content">
                    <div className="label-text-2">Dataset Title</div>
                  <div className="div-wrapper">
                    <p className="input-text-2">{name}</p>
                  </div>
                </div>

                <div  className="content">
                    <div className="label-text-2">Dataset Version</div>
                  <div className="div-wrapper">
                    <p className="input-text-2">{version}</p>
                  </div>
                </div>
                    <div style={{wordWrap:'break-word', marginLeft:'46px'}} className='pd-8'><SafeHtmlComponent data={description} /></div>
                </ModalBody>
                <ModalFooter>
                    {/* <HgButtonComponent buttonText={"Close"} isPrimary={false} onclickHandler={() => onAccept()} /> */}
                    <Button
                    className='btnspecial'
                variant="outlined"
                onClick={handleEditClick}
              >
                EDIT
              </Button>
                </ModalFooter>
            </Modal>
            </div>
    );
}

export default DatasetDescriptionPopupComponent;