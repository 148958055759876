import { formatErrorMessages } from "../utils/stringUtils";

/**
 * @constant SESSION_ACTIONS
 */
export const SESSION_ACTIONS = {
  LOGIN_LOADING: "LOGIN_LOADING",
  LOGIN_SUCCESS: "LOGIN_SUCCESS",
  LOGIN_FAILURE: "LOGIN_FAILURE",
  LOGIN_RESET: "LOGIN_RESET",
  LOGOUT: "LOGOUT",
};

export const ACCOUNT_ACTIONS = {
  ACCOUNT_STATE_RESET: "ACCOUNT_STATE_RESET",
  GET_CUREENT_USER_INFO_FROM_CACHE: "GET_CUREENT_USER_INFO_FROM_CACHE",
  SIGNUP_LOADING: "SIGNUP_LOADING",
  SIGNUP_SUCCESS: "SIGNUP_SUCCESS",
  SIGNUP_FAILURE: "SIGNUP_FAILURE",
  VERIFY_EMAIL_LOADING: "VERIFY_EMAIL_LOADING",
  VERIFY_EMAIL_SUCCESS: "VERIFY_EMAIL_SUCCESS",
  VERIFY_EMAIL_ERROR: "VERIFY_EMAIL_ERROR",
  RESET_PASSWORD_LOADING: "RESET_PASSWORD_LOADING",
  RESET_PASSWORD_SUCCESS: "RESET_PASSWORD_SUCCESS",
  RESET_PASSWORD_ERROR: "RESET_PASSWORD_ERROR",
  CHANGE_PASSWORD_LOADING: "CHANGE_PASSWORD_LOADING",
  CHANGE_PASSWORD_SUCCESS: "CHANGE_PASSWORD_SUCCESS",
  CHANGE_PASSWORD_ERROR: "CHANGE_PASSWORD_ERROR",
  CHANGE_PASSWORD_RESET: "CHANGE_PASSWORD_RESET",
  SET_RESET_PASSWORD_LOADING: "SET_RESET_PASSWORD_LOADING",
  SET_RESET_PASSWORD_SUCCESS: "SET_RESET_PASSWORD_SUCCESS",
  SET_RESET_PASSWORD_ERROR: "SET_RESET_PASSWORD_ERROR",
};

/**
 * @constant DATASET_ACTIONS
 */
export const DATASET_ACTIONS = {
  ROOT_DATASETS_LOADING: "ROOT_DATASETS_LOADING",
  ROOT_DATASETS_SUCCESS: "ROOT_DATASETS_SUCCESS",
  ROOT_DATASETS_ERROR: "ROOT_DATASETS_ERROR",
  ROOT_DATASET_INFO_LOADING: "ROOT_DATASET_INFO_LOADING",
  ROOT_DATASET_INFO_SUCCESS: "ROOT_DATASET_INFO_SUCCESS",
  ROOT_DATASET_INFO_ERROR: "ROOT_DATASET_INFO_ERROR",
  ADD_DATASET_LOADING: "ADD_DATASET_LOADING",
  ADD_DATASET_SUCCESS: "ADD_DATASET_SUCCESS",
  ADD_DATASET_ERROR: "ADD_DATASET_SUCCESS",
  UPDATE_DATASET_LOADING: "UPDATE_DATASET_LOADING",
  UPDATE_DATASET_SUCCESS: "UPDATE_DATASET_SUCCESS",
  UPDATE_DATASET_ERROR: "UPDATE_DATASET_ERROR",
  UDPATE_DATASET_STATUS_LOADING: "UDPATE_DATASET_STATUS_LOADING",
  UDPATE_DATASET_STATUS_SUCCESS: "UDPATE_DATASET_STATUS_SUCCESS",
  UDPATE_DATASET_STATUS_ERROR: "UDPATE_DATASET_STATUS_ERROR",
  SHARE_DATASET_LOADING: "SHARE_DATASET_LOADING",
  SHARE_DATASET_SUCCESS: "SHARE_DATASET_SUCCESS",
  SHARE_DATASET_ERROR: "SHARE_DATASET_ERROR",
  DATASET_VERSIONS_LOADING: "DATASET_VERSIONS_LOADING",
  DATASET_VERSIONS_SUCCESS: "DATASET_VERSIONS_SUCCESS",
  DATASET_VERSIONS_ERROR: "DATASET_VERSIONS_ERROR",
  DATASET_VERSION_INFO_LOADING: "DATASET_VERSION_INFO_LOADING",
  DATASET_VERSION_INFO_SUCCESS: "DATASET_VERSION_INFO_SUCCESS",
  DATASET_VERSION_INFO_ERROR: "DATASET_VERSION_INFO_ERROR",
  DATASET_DOWNLOAD_LOADING: "DATASET_DOWNLOAD_LOADING",
  DATASET_DOWNLOAD_SUCCESS: "DATASET_DOWNLOAD_SUCCESS",
  DATASET_DOWNLOAD_ERROR: "DATASET_DOWNLOAD_ERROR",
  DATASET_SHARED_USERS_LOADING: "DATASET_SHARED_USERS_LOADING",
  DATASET_SHARED_USERS_SUCCESS: "DATASET_SHARED_USERS_SUCCESS",
  DATASET_SHARED_USERS_ERROR: "DATASET_SHARED_USERS_ERROR",
  DATASET_SHARE_ENABLE_DISABLE_LOADING: "DATASET_SHARE_ENABLE_DISABLE_LOADING",
  DATASET_SHARE_ENABLE_DISABLE_SUCCESS: "DATASET_SHARE_ENABLE_DISABLE_SUCCESS",
  DATASET_SHARE_ENABLE_DISABLE_ERROR: "DATASET_SHARE_ENABLE_DISABLE_ERROR",
  ADD_DATASET_VERSION_LOADING: "ADD_DATASET_VERSION_LOADING",
  ADD_DATASET_VERSION_SUCCESS: "ADD_DATASET_VERSION_SUCCESS",
  ADD_DATASET_VERSION_ERROR: "ADD_DATASET_VERSION_ERROR",
  OCEAN_DATASETS_LOADING: "OCEAN_DATASETS_LOADING",
  OCEAN_DATASET_LIST_SUCCESS: "OCEAN_DATASET_LIST_SUCCESS",
  OCEAN_DATASET_LIST_ERROR: "OCEAN_DATASET_LIST_ERROR",
  OCEAN_DATA_SET_FILTER: "OCEAN_DATA_SET_FILTER",
  OCEAN_FILTER_DATASETS_LOADING: "OCEAN_FILTER_DATASETS_LOADING",
  OCEAN_FILTER_DATASETS_SUCCESS: "OCEAN_FILTER_DATASETS_SUCCESS",
  OCEAN_FILTER_DATASETS_FALIURE: "OCEAN_FILTER_DATASETS_FALIURE",
  UPDATE_DATASET_ACCESS_TYPE: "UPDATE_DATASET_ACCESS_TYPE",
  UPDATE_DATASET_ACCESS_TYPE_SUCCESS: "UPDATE_DATASET_ACCESS_TYPE_SUCCESS",
  UPDATE_DATASET_ACCESS_TYPE_ERROR: "UPDATE_DATASET_ACCESS_TYPE_ERROR",
  REMOVE_ACCESS_TYPE_LOADING: "REMOVE_ACCESS_TYPE_LOADING",
  REMOVE_ACCESS_TYPE_SUCCESS: "REMOVE_ACCESS_TYPE_SUCCESS",
  REMOVE_ACCESS_TYPE_ERROR: "REMOVE_ACCESS_TYPE_ERROR",
};

/**
 * @constant PUBLISH_ACTIONS
 */

export const PUBLISH_ACTIONS = {
  PUBLISH_DATASET_LOADING: "PUBLISH_DATASET_LOADING",
  PUBLISH_DATASET_ADD: "PUBLISH_DATASET_ADD",
  PUBLISH_DATASET_ERROR: "PUBLISH_DATASET_ERROR",
  PUBLISH_DATASET_UPLOAD_LOADING: "PUBLISH_DATASET_UPLOAD_LOADING",
  PUBLISH_DATASET_UPLOAD: "PUBLISH_DATASET_UPLOAD",
  PUBLISH_DATASET_UPLOAD_ERROR: "PUBLISH_DATASET_UPLOAD_ERROR",
  DATASET_LIST_LOADING: "DATASET_LIST_LOADING",
  DATASET_LIST_SUCCESS: "DATASET_LIST_SUCCESS",
  DATASET_LIST_ERROR: "DATASET_LIST_ERROR",
  PUBLISH_DATASET_DETAILS_LOADING: "PUBLISH_DATASET_DETAILS_LOADING",
  PUBLISH_DATASET_DETAILS_SUCCESS: "PUBLISH_DATASET_DETAILS_SUCCESS",
  PUBLISH_DATASET_DETAILS_ERROR: "PUBLISH_DATASET_DETAILS_ERROR",
  UPDATED_DESCRIPTION_LOADING: "UPDATED_DESCRIPTION_LOADING",
  UPDATED_DESCRIPTION_SUCCESS: "UPDATED_DESCRIPTION_SUCCESS",
  UPDATED_DESCRIPTION_ERROR: "UPDATED_DESCRIPTION_ERROR",
};

/**
 * @constant MODEL_ACTIONS
 */
export const MODEL_ACTIONS = {
  RECENTLY_USED_MODELS_LOADING: "RECENTLY_USED_MODELS_LOADING",
  RECENTLY_USED_MODELS_SUCCESS: "RECENTLY_USED_MODELS_SUCCESS",
  RECENTLY_USED_MODELS_ERROR: "RECENTLY_USED_MODELS_ERROR",
  SEARCH_MODELS_LOADING: "SEARCH_MODELS_LOADING",
  SEARCH_MODELS_SUCCESS: "SEARCH_MODELS_SUCCESS",
  SEARCH_MODELS_ERROR: "SEARCH_MODELS_ERROR",
  SEARCH_FILTER_OPTIONS_LOADING: "SEARCH_FILTER_OPTIONS_LOADING",
  SEARCH_FILTER_OPTIONS_SUCCESS: "SEARCH_FILTER_OPTIONS_SUCCESS",
  SEARCH_FILTER_OPTIONS_ERROR: "SEARCH_FILTER_OPTIONS_SUCCESS",
  MODEL_DETAILS_LOADING: "MODEL_DETAILS_LOADING",
  MODEL_DETAILS_SUCCESS: "MODEL_DETAILS_SUCCESS",
  MODEL_DETAILS_ERROR: "MODEL_DETAILS_ERROR",
  MODEL_PREDICT_LOADING: "MODEL_PREDICT_LOADING",
  MODEL_PREDICT_SUCCESS: "MODEL_PREDICT_SUCCESS",
  MODEL_PREDICT_ERROR: "MODEL_PREDICT_ERROR",
  MODEL_PREDICT_DIALOGUE_LOADING: "MODEL_PREDICT_DIALOGUE_LOADING",
  MODEL_PREDICT_DIALOGUE_SUCCESS: "MODEL_PREDICT_DIALOGUE_SUCCESS",
  MODEL_PREDICT_DIALOGUE_ERROR: "MODEL_PREDICT_DIALOGUE_ERROR",
  DIALOGUE_CHAT_BOX_UPDATE: "DIALOGUE_CHAT_BOX_UPDATE",
  MODEL_REWORD_SUGGESTIONS_LOADING: "MODEL_REWORD_SUGGESTIONS_LOADING",
  MODEL_REWORD_SUGGESTIONS_SUCCESS: "MODEL_REWORD_SUGGESTIONS_SUCCESS",
  MODEL_REWORD_SUGGESTIONS_ERROR: "MODEL_REWORD_SUGGESTIONS_ERROR",
  MODEL_RESET_STATE: "MODEL_RESET_STATE",
};

/**
 * Misc
 */
export const COMMON_ERROR_MESSAGE =
  "Oops! Something went wrong. Please try again.";

export const getErrorMessage = (error, messageKey) => {
  if (error.response) {
    const errorMessage =
      error.response["status"] === 400
        ? error.response.data[
            messageKey !== undefined ? messageKey : "errorMessage"
          ] || error.response.data["description"]
        : COMMON_ERROR_MESSAGE;
    return formatErrorMessages(errorMessage);
  } else {
    return "Services temporarily unavailable";
  }
};

export const COMMON_FAILURE = {
  type: "COMMON_FAILURE",
  error: "Oops! Something went wrong.\nPlease try again.",
};
