import React from 'react'
import { useState, useEffect} from 'react'
import { Modal, ModalFooter, ModalHeader, Button, ModalBody } from 'reactstrap'
import '../../styles/editAccessPopupComponent.scss'
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import HgButtonComponent from './hgButtonComponent';
import { useDispatch } from 'react-redux';
import { updatedAccessType } from '../../actions/datasetActions';

const EditAccessPopupComponent = (props) => {
  const { toggle, modal, user, rootDataset } = props
  const [selectedAccessType, setSelectedAccessType] = useState(user.access_type);
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const accessTypes = ["ADMIN", "EDITOR", "VIEWER"];
  const dispatch = useDispatch();

  useEffect(() => {
    setSelectedAccessType(user.access_type);
  }, [user]);

  const handleDropdownToggle = () => {
    setIsDropdownOpen(!isDropdownOpen);
  };

  const handleOptionClick = (type) => {
    setSelectedAccessType(type);
    setIsDropdownOpen(false); 
  };

  const handleSaveClick = () =>{
    if (!rootDataset.uid) {
      console.error("Dataset ID is missing");
      return;
    }
    dispatch(updatedAccessType(user.email, rootDataset.uid, selectedAccessType));
    toggle();
  }

  return (
    <Modal isOpen={modal} toggle={toggle} size={"sm"} className='edit-access-modal' {...props}>
      <ModalHeader>Edit User Access</ModalHeader>
      <ModalBody>
        <div className='sub-heading'>A dialog is a type of modal window that appears in front of app content to provide critical information, or prompt for a decision to be made. </div>
        <div className='user-details'>
          <div className='user-first-name'>
            <label className='name'>Name</label>
            <div className='user-info'>{user.first_name}</div>
          </div>
          <div className='user-last-name'>
            <label className='name'>Name</label>
            <div className='user-info'>{user.last_name}</div>
          </div>
          <div className='email-section'>
            <label className='name'>Email</label>
            <div className='user-info'>{user.email}</div>
          </div>
          <div className='access-section'>
            <label className='name'>Access Type</label>
            <div className='custom'>
              <div className='dropdown' onClick={handleDropdownToggle}>
                <span>{selectedAccessType}</span>
                <ArrowDropDownIcon />
              </div>
            </div>
          </div>
          {isDropdownOpen && (
                <div className='dropdown-options'>
                  {accessTypes.map((type) => (
                    <div className="dropdown-item" onClick={() => handleOptionClick(type)}>{type}</div>
                  ))}
                </div>
              )}
        </div>
      </ModalBody>
      <ModalFooter>
      <HgButtonComponent buttonText={"Cancel"} isPrimary={false} onclickHandler={toggle} />
      <HgButtonComponent buttonText={"Save"} isPrimary={true} onclickHandler={() => handleSaveClick()} type="submit" />
      </ModalFooter>
    </Modal>
  )
}

export default EditAccessPopupComponent