import { PUBLISHING_MANAGAER_SECTION, SIDEBAR_TABS } from "../config";
import HeaderComponent from "./pure/headerComponent";
import SidebarComponent from "./pure/sidebarComponent";
import "../styles/publishingManager.scss";
import { useEffect, useState } from "react";
import Publish from "./publishComponent";
import SearchDatasets from "./pure/searchDatasets";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import Snackbar from "@mui/material/Snackbar";
import Alert from "@mui/material/Alert";

const PublishingManager = ({ publishData }) => {
  const [currentSection, setCurrentSection] = useState(
    PUBLISHING_MANAGAER_SECTION.PUBLISH
  );
  const [searchModal, setSearchModal] = useState(false);
  const [open, setOpen] = useState(false);
  // decide which component will be render
  const handleCurrentSection = (e) => {
    if (e.target.innerText === "Publishing") {
      setCurrentSection(PUBLISHING_MANAGAER_SECTION.PUBLISH);
    } else if (e.target.innerText === "Analytics") {
      setCurrentSection(PUBLISHING_MANAGAER_SECTION.ANALYTICS);
    }
  };

  // set the search modal to true
  const handleMyDatasetSearch = () => {
    setSearchModal(true);
  };

  // IIFE to handle the Displaying the different component
  const handleRenderCurrentSection = () => {
    switch (currentSection) {
      case PUBLISHING_MANAGAER_SECTION.PUBLISH:
        // we'll return the Publish Component
        return <Publish />;
      case PUBLISHING_MANAGAER_SECTION.ANALYTICS:
        // we'll return the Analytics Component
        return <h1>Analytics Compnent</h1>;
      default:
        return <h1>Hello</h1>;
    }
  };

  // opening up snackbar
  const handleClick = () => {
    setOpen(true);
  };

  // closing snackbar
  const handleClose = (event, reason) => {
    setOpen(false);
  };
  useEffect(() => {
    if (publishData.status === 200) {
      handleClick();
    }
  }, [publishData.status]);
  return (
    <div>
      <Snackbar
        open={open}
        autoHideDuration={2000}
        onClose={handleClose}
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
      >
        <Alert
          onClose={handleClose}
          severity="success"
          variant="filled"
          sx={{ width: "100%" }}
        >
          Your dataset has been successfully published 🎉🎉🎉
        </Alert>
      </Snackbar>
      <HeaderComponent />
      <SidebarComponent activeTab={SIDEBAR_TABS.publishingManager} />
      <div className="publishing-container">
        <p className="publishing-heading">Ocean of Data Publishing Manager</p>
        <div className="publishing-header-control">
          <div className="publishing-section-control">
            <button
              onClick={handleCurrentSection}
              style={{
                color: `${
                  currentSection === PUBLISHING_MANAGAER_SECTION.PUBLISH
                    ? "#00639A"
                    : "#828282"
                }`,
                borderBottom: `${
                  currentSection === PUBLISHING_MANAGAER_SECTION.PUBLISH
                    ? "3px solid #00639a"
                    : "none"
                }`,
              }}
            >
              Publishing
            </button>

            {/* uncomment the below button to enable the Analytic component */}
            {/* <button
              onClick={handleCurrentSection}
              style={{
                color: `${
                  currentSection === PUBLISHING_MANAGAER_SECTION.ANALYTICS
                    ? "#00639A"
                    : "#828282"
                }`,
                borderBottom: `${
                  currentSection === PUBLISHING_MANAGAER_SECTION.ANALYTICS
                    ? "3px solid #00639a"
                    : "none"
                }`,
              }}
            >
              Analytics
            </button> */}
          </div>
          <div className="publish-new">
            {/* onclick this will show the search component */}
            <button onClick={handleMyDatasetSearch}>
              <span style={{ fontSize: "15px" }}>Publish New</span>
            </button>
          </div>
        </div>
        <div className="rendered-component">{handleRenderCurrentSection()}</div>
      </div>

      <SearchDatasets
        searchModal={searchModal}
        setSearchModal={setSearchModal}
      />
    </div>
  );
};
// mapping publish data to props
const mapStateToProps = (state) => {
  return {
    publishData: state.publish,
  };
};

// mapping publish actions to props
const mapDispatchToProps = (dispatch) => {
  return {
    // publishDataset: bindActionCreators(publishDataset, dispatch),
    // uploadDataset: bindActionCreators(uploadDataset, dispatch),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(PublishingManager);
