import React from 'react'
import '../../styles/authorizationPopupComponent.scss'

const AuthorizationPopupComponent = (props) => {
    const {showPopup, onClose} = props
  return (
    <>
    {showPopup && <div className='authorization-popup'>
        <div className='heading'>Authorization Needed</div>
        <div className='message'>Access limited to Download and View. To Edit files please contact Admin.</div>
        <button className='btn' onClick={onClose}>OK</button>
    </div>}
    </>
  )
}

export default AuthorizationPopupComponent