import PublishApi from "../api/publishApi";
import { PUBLISH_ACTIONS } from "./actionTypes";

export function addPublishDataset(payload) {
  return { type: PUBLISH_ACTIONS.PUBLISH_DATASET_ADD, payload: payload };
}

export function loadingPublishDataset() {
  return { type: PUBLISH_ACTIONS.PUBLISH_DATASET_LOADING };
}

export function errorPublishDataset(payload) {
  return { type: PUBLISH_ACTIONS.PUBLISH_DATASET_ERROR, payload: payload };
}

export function uploadPublishDataset(payload) {
  return { type: PUBLISH_ACTIONS.PUBLISH_DATASET_UPLOAD, payload: payload };
}

export function loadingUploadPublishDataset() {
  return { type: PUBLISH_ACTIONS.PUBLISH_DATASET_UPLOAD_LOADING };
}

export function datasetListLoading() {
  return { type: PUBLISH_ACTIONS.DATASET_LIST_LOADING };
}

export function datasetListSuccess(payload) {
  return { type: PUBLISH_ACTIONS.DATASET_LIST_SUCCESS, payload };
}

export function datasetsListError() {
  return { type: PUBLISH_ACTIONS.DATASET_LIST_ERROR };
}

export function publishDatasetDetailsLoading() {
  return { type: PUBLISH_ACTIONS.PUBLISH_DATASET_DETAILS_LOADING };
}

export function publishDatasetDetailsSuccess(payload) {
  return { type: PUBLISH_ACTIONS.PUBLISH_DATASET_DETAILS_SUCCESS, payload };
}

export function publishDatasetDetailsError() {
  return { type: PUBLISH_ACTIONS.PUBLISH_DATASET_DETAILS_ERROR };
}

export function errorUploadPublishDataset(payload) {
  return {
    type: PUBLISH_ACTIONS.PUBLISH_DATASET_UPLOAD_ERROR,
    payload: payload,
  };
}

export function editDescriptionLoading() {
  return { type: PUBLISH_ACTIONS.UPDATED_DESCRIPTION_LOADING };
}

export function editDescriptionSuccess(payload) {
  return { type: PUBLISH_ACTIONS.UPDATED_DESCRIPTION_SUCCESS, payload };
}

export function editDescriptionError() {
  return { type: PUBLISH_ACTIONS.UPDATED_DESCRIPTION_ERROR };
}

export function publishDataset(searchParams, page = 1) {
  return async (dispatch) => {
    // wait while data is being fetch
    dispatch(loadingPublishDataset());
    // call the API to get data and then send data to reducer
    try {
      console.log(page);
      const data = await PublishApi.getDatasetToBePublish(searchParams, page);

      dispatch(addPublishDataset(data.data));
    } catch (error) {
      // if API call fail dispatch the error action
      console.error(`error while fetching the datasets ${error}`);
      dispatch(errorPublishDataset(error));
    }
  };
}

export function uploadDataset(child_dataset_id, status, license_slug) {
  return async (dispatch) => {
    // wait while data is being upload
    dispatch(loadingUploadPublishDataset());
    // upload the dataset
    try {
      // upload the dataset to publish
      let requestObj = {
        child_dataset_id,
        status,
        license_slug,
        license_url: "",
      };
      const data = await PublishApi.uploadDatasetToPublish(requestObj);

      dispatch(uploadPublishDataset({ status: data.status }));
    } catch (error) {
      // triggred after failing the upload
      console.error(error);
      dispatch(errorUploadPublishDataset(error));
    }
  };
}

// action to fetch the list of published datasets with pagination
export function getPublishDatasetsList(page) {
  return async (dispatch) => {
    // wait while data is being fetch
    dispatch(datasetListLoading());
    try {
      // API call to fetch the dataset list for the given page
      const data = await PublishApi.getDatasetsList(page);
      // dispatching a success action with the fetched dataset list
      dispatch(datasetListSuccess(data.data));
    } catch (error) {
      console.log(`error while fetching the data ${error}`);
      // dispatching an error action in case of failure
      dispatch(datasetsListError());
    }
  };
}

// action to fetch the details of a specific published dataset by ID
export function getPublishedDatasetDetails(publish_id) {
  return async (dispatch) => {
    // wait while data is being fetch
    dispatch(publishDatasetDetailsLoading());
    try {
      //API call to fetch the dataset details for the given ID
      const data = await PublishApi.getPublishedDatasetDetails(publish_id);
      console.log("detail", data);
      // dispatching a success action with the fetched dataset details
      dispatch(publishDatasetDetailsSuccess(data));
    } catch (error) {
      console.log(`error while fetching the data ${error}`);
      // dispatching an error action in case of failure
      dispatch(publishDatasetDetailsError());
    }
  };
}

export function editDescription(
  publish_uid,
  documentation_link,
  description,
  additional_documentation_text
) {
  return async (dispatch) => {
    // wait while data is being updating
    dispatch(editDescriptionLoading());
    // Call the API to update the description
    return PublishApi.updatedDescription(
      publish_uid,
      documentation_link,
      description,
      additional_documentation_text
    )
      .then((res) => {
        //  dispatching a success action with updated data
        dispatch(
          editDescriptionSuccess({
            publish_uid,
            documentation_link,
            description,
            additional_documentation_text,
          })
        );
      })
      .catch((error) => {
        console.log(`error while fetching the data ${error}`);
        // dispatching an error action in case of failure
        dispatch(editDescriptionError());
      });
  };
}
