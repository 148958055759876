import React, { useState } from 'react'
import { Modal, ModalBody, ModalHeader, Button, ModalFooter } from 'reactstrap'
import '../../styles/addSampleDatasetPopupComponent.scss'
import HgButtonComponent from './hgButtonComponent'
import { useRef } from 'react'

const AddSampleDatasetPopup = (props) => {
  const { modal, toggle } = props
  const fileInputRef = useRef();
  const [selectedFile, setSelectedFile] = useState(null);  // State to hold the selected file


  // function to open the file picker 
  const triggerFilePicker = () => {
    fileInputRef.current.click();
  };

  // function to handle file input change
  const handleFileChange = (e) => {
    const file = e.target.files[0]; 
    if (file) {
      setSelectedFile(file); // Update state with the selected file
    }
  }

  return (
    <div>
      <div className='add-sample-dataset-component'>
        <Modal isOpen={modal} toggle={toggle} size={"md"} centered={true} className='add-sample-dataset-component' {...props}>
          <ModalHeader>
            <p className='heading'>Choose Sample Data</p>
            <p className='sub-heading'>Basic instructions for creating a listing for Ocean of Data Publishing</p>
          </ModalHeader>
          <ModalBody>
             {/* Section for data selection */}
            <p className='data'>Data Selection to Share </p>
            <div className='upload-file-section'>
              <p className='upload-file'>Sample Upload Drop Here</p>
              {/* File upload button to open the file picker */}
              <Button className='upload-file-btn' onClick={() => triggerFilePicker()}>Upload File</Button>
              {/* Hidden file input */}
              <input type='file' ref={fileInputRef} style={{display: "none"}} onChange={handleFileChange}/>
            </div>
            <p className='create-package'>Create Package Name (Optional)</p>
            <input type='text' className='package-name' placeholder='Package Name' />
          </ModalBody>
          <ModalFooter>
            {/* Footer buttons for "Cancel" and "Next" */}
          <HgButtonComponent buttonText={"Cancel"} isPrimary={false} onclickHandler={toggle} />
          <HgButtonComponent buttonText={"NEXT"} isPrimary={true} onclickHandler={toggle} />
          </ModalFooter>
        </Modal>
      </div>
    </div>
  )
}

export default AddSampleDatasetPopup