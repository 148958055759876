import { combineReducers } from "redux";
import { connectRouter } from "connected-react-router";
import session from "./sessionReducer";
import dataset from "./datasetReducer";
import account from "./accountReducer";
import publish from "./publishReducer";

const appReducer = (history) =>
  combineReducers({
    router: connectRouter(history),
    session,
    account,
    dataset,
    publish,
  });

const rootReducer = (state, action) => {
  return appReducer(state, action);
};

export default rootReducer;
