import React, { Component, createRef } from "react";
import { Link } from "react-router-dom";
import { RxCross1 } from "react-icons/rx";
import { Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";

import {
  DummyImgUploadDataset,
  ImgAddLarge,
  ImgCog,
  ImgCross,
  ImgDelete,
  ImgDownload,
  ImgExpand,
  ImgInfo,
  ImgInfoSm,
  ImgMoreHoriz,
  ImgOMTLogo,
  ImgPencil,
  ImgPreview,
  ImgPreviewSmall,
  ImgShare,
  Logo,
} from "../images";
import editImage from "../images/edit.png";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import * as datasetActions from "../actions/datasetActions";
import HeaderComponent from "./pure/headerComponent";
import "../styles/myDatasetsComponent.scss";
import "../styles/dsgSearchComponent.scss";
import HgDropdownComponent from "./pure/hgDropdownComponent";
import moment from "moment/moment";
import { Collapse, Table } from "reactstrap";
import {
  calculateMemorySize,
  formatDate,
  parseDatasetSchema,
} from "../utils/utils";
import HgButtonComponent from "./pure/hgButtonComponent";
import axios from "axios";
import SidebarComponent from "./pure/sidebarComponent";
import {
  DATASET_STATUS,
  DOCUMENT_ROOT,
  SIDEBAR_TABS,
  VISIBILITY,
  VISIBILITY_TYPE,
} from "../config";
import styled from "styled-components";
import BackToMainComponent from "./pure/backToMainComponent";
import SafeHtmlComponent from "./pure/safeHtmlComponent";
import DatasetVersionsComponent from "./pure/datasetVersionsComponent";
import DatasetPreviewComponent from "./pure/datasetPreviewComponent";
import Loader from "./loaderComponent";
import ShareDatasetComponent from "./pure/shareDatasetComponent";
import DatasetAccessComponent from "./pure/datasetAccessComponent";
import DatasetColumnDetailsComponent from "./pure/datasetColumnDetailsPopupComponent";
import DatasetColumnDetailsPopComponent from "./pure/datasetColumnDetailsPopupComponent";
import DatasetDescriptionPopupComponent from "./pure/datasetDescriptionPopupComponent";
import DatasetDescriptionEditPopupComponent from "./pure/datasetDescriptionEditPopupComponent";
import DatasetNameEditPopupComponent from "./datasetNameEditPopupComponent";
import TagEditPopupComponent from "./pure/tagEditPopupComponent";
import closeIcon1 from "../images/arrow_drop_down_24px.svg";
import { Button } from "@mui/material";
import ManageAccessPopupComponent from "./pure/manageAccessPopupComponent";
import GeospatialVariableEditComponent from "./pure/geospatialVariableEditComponent"
import ViewLicensePopupComponent from "./viewLicensePopupComponent";
import ViewVersionsPopupComponent from "./viewVersionsPopupComponent";
import EditDatasetFilesPopupComponent from "./pure/editDatasetFilesPopupComponent";
import ViewDatasetFilesPopupComponent from "./pure/viewDatasetFilesPopupComponent";
import CustomTooltip from "./pure/toolTipViewDatasetComponent";
import { version } from "dompurify";

const DatasetName = styled.div`
white-space: nowrap;
overflow: hidden;
display: inline-block;
cursor: pointer;
text-overflow: ellipsis;
`;

const trimDatasetName = (name) => {
  if (name.length > 25) {
    return name.substring(0, 25) + "...";
  }
  return name;
};

class ViewDatasetsComponent extends Component {
  constructor(props) {
    super(props);
    this.state = {
      versionOpen: false,
      licenseOpen: false,
      isSearched: false,
      preview_table_header: [],
      preview_table_data: [],
      datasetSchema: [],
      versionModal: false,
      isPreviewLoading: false,
      isColumnDetailsLoading: false,
      shareDatasetModal: false,
      columnDetailsModal: false,
      desciptionModal: false,
      descriptioneditModal: false,
      dataseteditModal: false,
      tageditModal: false,
      isDeleteModalOpen: false,
      datasetToDelete: null,
      manageAccessModal: false,
      geospatialVariableEditModal: false,
      editDatasetFiles: false,
      viewDatasetFiles: false,
      primaryFileName: '', 
      secondaryFile:[], // To store the base name
      showAllTags: false,
      showDropdown: false,
      versions:[]
    };
  }


  




  handleMouseEnter = () => {
    this.setState({ showAllTags: true });
  };

  handleMouseLeave = () => {
    this.setState({ showAllTags: false });
  };


  toggleLicenseModal = () => {
    this.setState({
      licenseOpen: !this.state.licenseOpen,
    });
  };
  toggleVersionModal = () => {
    this.setState({
      versionOpen: !this.state.versionOpen,
    });
  };
  toggleDeleteModal = (dataset) => {
    this.setState({
      isDeleteModalOpen: !this.state.isDeleteModalOpen,
      datasetToDelete: dataset,
    });
  };

  closeDeleteModal = () => {
    this.setState({
      isDeleteModalOpen: false,
      datasetToDelete: null,
    });
  };
  handleDeleteDataset = () => {
    const { datasetToDelete } = this.state;
    if (datasetToDelete) {
      this.props.actions.updateDatasetStatus(
        datasetToDelete.uid,
        DATASET_STATUS.DELETED,
        () => {
          this.props.history.push(`/my-datasets`);
        }
      );
      this.closeDeleteModal();
    }
  };

  updateDatasetStatus = (dataset, status) => {
    this.props.actions.updateDatasetStatus(dataset.uid, status, () => {
      this.props.history.push(`/my-datasets`);
    });
  };

  handleSave = (updatedDescription, additionalNotes, isToggled) => {
    this.setState({
      description: updatedDescription,
    },
    () => {
      // Close the modal after state is updated
  });
  };

 handleGeoSave = (updatedLatitude, updatedLongitude, isToggled) => {
    // Update the state with the new data
    this.setState((prevState) => {
        const updatedDataset = {
            ...prevState.childDataset,
            latitude: updatedLatitude,
            longitude: updatedLongitude,
        };
        return { childDataset: updatedDataset };
    });

    // Alternatively, re-fetch the dataset if necessary
    // this.fetchDatasetData();
};


// formatLargeNumber = (num) => {
//   if (num >= 1_000_000) {
//     return (num / 1_000_000).toFixed(2) + "M";
//   } else if (num >= 1_000) {
//     return (num / 1_000).toFixed(2) + "K";
//   }
//   return num.toString();
// };

formatLargeNumber = (num) => {
  // Check for null, undefined, or non-numeric values
  if (num == null || isNaN(num)) {
    return "N/A"; // Or handle the invalid value appropriately
  }

  if (num >= 1_000_000) {
    return (num / 1_000_000).toFixed(2) + "M";
  } else if (num >= 1_000) {
    return (num / 1_000).toFixed(2) + "K";
  }
  return num.toString();
};


  componentDidMount() {
    const { uid } = this.props.match.params;
   
    this.props.actions.getRootDatasetInfo(uid, (previewUrl, Schema_Url,primary_file_name, secondary_file, version_availability ) => {
      this.getPreview(previewUrl);
      this.getDatasetSchema(Schema_Url);

this.setState({secondaryFile: secondary_file})

this.setState({versions: version_availability})

console.log('ss', this.state.versions)

    if (primary_file_name) {

      // Split the file name into base name and extension
      const primaryFileNameParts = primary_file_name.split(".");
      const primaryFileBaseName = primaryFileNameParts.slice(0, -1).join("."); // Everything except the last part
      const primaryFileExtension = primaryFileNameParts.length > 1 ? primaryFileNameParts.pop().toLowerCase() : "";

      // Store the split parts in state
      this.setState({
        primaryFileName: primary_file_name,
        primaryFileBaseName,
        primaryFileExtension,
      });

    } else {
      console.warn("primary_file_name is not available.");
    }
  });


    this.props.actions.getDatasetVerionsList(
      uid,
      VISIBILITY.PRIVATE,
      DATASET_STATUS.PROCESSED
    );
    this.props.actions.getDatasetSharedUsers(uid);

  }

  

  componentDidUpdate(prevProps, prevState) {
    const { uid } = this.props.match.params;

    // Define modals that should trigger a data refresh on close
    const modalsToCheck = ["descriptioneditModal","dataseteditModal", "geospatialVariableEditModal", "tageditModal", "editDatasetFiles"];

    modalsToCheck.forEach((modal) => {
        if (!this.state[modal] && prevState[modal] !== this.state[modal]) {
            this.fetchDatasetData(uid); // Re-fetch data when modal closes
        }
    });
}



fetchDatasetData = (uid) => {
    this.props.actions.getRootDatasetInfo(uid, (previewUrl, schemaUrl) => {
        this.getPreview(previewUrl);
        this.getDatasetSchema(schemaUrl);
    });
    // this.props.actions.getDatasetVersionsList(
    //     uid,
    //     VISIBILITY.PRIVATE,
    //     DATASET_STATUS.PROCESSED
    // );
    this.props.actions.getDatasetSharedUsers(uid);
};

  componentWillUnmount() {
    // this.props.actions.getRootDatasetInfoReset();
  }

  getPreview = (url) => {
    if (url) {
      this.setState({ isPreviewLoading: true });
      axios
        .get(url)
        .then(({ data }) => {
          this.setState({
            preview_table_header: Object.keys(data[0]),
            preview_table_data: [...data],
          });
        })
        .catch((error) => {
          this.setState({ preview_table_header: [], preview_table_data: [] });
        })
        .finally(() => {
          this.setState({ isPreviewLoading: false });
        });
    } else {
    }
  };

  getDatasetSchema = (url) => {
    if (url) {
      this.setState({ isColumnDetailsLoading: true });
      axios
        .get(url)
        .then(({ data }) => {
          this.setState({ datasetSchema: data });
        })
        .catch((error) => {
          this.setState({ datasetSchema: [] });
        })
        .finally(() => {
          this.setState({ isColumnDetailsLoading: false });
        });
    } else {
    }
  };

  searchModel = () => {};

  onChangeSearch = () => {};

  selectDatasetHandler = (e, selectedDatasetUid) => {
    const checked = e.target.checked;
    if (checked) {
      const selectedDatasets = [
        ...this.state.selectedDatasets,
        selectedDatasetUid,
      ];
      this.setState({ selectedDatasets });
    } else {
      const fitleredSelectedDatasets = this.state.selectedDatasets.filter(
        (item) => item !== selectedDatasetUid
      );
      this.setState({ selectedDatasets: fitleredSelectedDatasets });
    }
  };

  selectedDatasetToViewInfo = (item) => {
    this.setState({ selectedDatasetToView: [item] });
  };

  toggleDropdown7 = () => {
    console.log('dd open')
    this.setState((prevState) => ({ showDropdown: !prevState.showDropdown }));
  };

  toggleCollapse = (item) => {
    console.log(`Toggling modal for: ${item}`);
    switch (item) {
      case "description":
        this.setState({ descOpen: !this.state.descOpen });
        break;
      case "actions":
        this.setState({ descActions: !this.state.descActions });
        break;
      case "shareDataset":
        this.setState({ shareDatasetModal: !this.state.shareDatasetModal });
        break;
      case "columnDetails":
        this.setState({ columnDetailsModal: !this.state.columnDetailsModal });
        break;
      case "desciptionModal":
        this.setState({ desciptionModal: !this.state.desciptionModal });
        break;
        case "descriptioneditModal":
            this.setState({ descriptioneditModal: !this.state.descriptioneditModal });
            break;
            case "dataseteditModal":
            this.setState({ dataseteditModal: !this.state.dataseteditModal });
            break;
        case "tageditModal":
              this.setState({ tageditModal: !this.state.tageditModal });
              break;
      case "manageAccessModal":
        this.setState({ manageAccessModal: !this.state.manageAccessModal});
        break;
        case "geospatialVariableEditModal":
          this.setState({ geospatialVariableEditModal: !this.state.geospatialVariableEditModal});
          break;
      case "editDatasetFiles":
        this.setState({ editDatasetFiles: !this.state.editDatasetFiles});
        break;
        case "viewDatasetFiles":
          this.setState({ viewDatasetFiles: !this.state.viewDatasetFiles});
          break;
      default:
        break;
    }
  };

  openViewDateset = (uid) => {
    this.props.history.push(`view-dataset/${uid}`);
  };

  openAddSet = () => {
    this.props.history.push(`add-dataset`);
  };

  onEditDataset = (uid) => {
    this.props.history.push(`/edit-dataset/${uid}`);
  };

  updateDatasetStatus = (dataset, status) => {
    this.props.actions.updateDatasetStatus(dataset.uid, status, () => {
      this.props.history.push(`/my-datasets`);
    });
  };

  toggleVersionsPopup = () => {
    this.setState({ versionModal: !this.state.versionModal });
  };

  getVersionInfo = (item) => {
    if (item.uid) {
      this.setState({ preview_table_header: [], preview_table_data: [] });
      this.props.actions.getDatasetVersionInfo(item.uid, this.getPreview);
      this.toggleVersionsPopup();
    }
  };


  onShareDataset = (rootDatasetId, params) => {
    this.props.actions.shareDataset(rootDatasetId, params, () => {
      this.toggleCollapse("shareDataset");
    });
  };

  render() {
    const childDataset = this.props.dataset.datasetInfo.latest_version;
    const rootDataset = this.props.dataset.datasetInfo;
    const sharedUsers = this.props.dataset.datasetSharedWithUsers;
    const secondaryFile = this.state.secondaryFile


    console.log("inside render", this.state.versions)
    const {showAllTags} = this.state.showAllTags

    const tags = childDataset.tags || [];
    const visibleTags = tags.slice(0, 3);
    const remainingTagsCount = tags.length - 3;
    

    const existingTags = childDataset.tags?.map((tag) => {
      try {
        const validJsonString = tag.replace(/'/g, '"');
    return JSON.parse(validJsonString);
      
      } catch (error) {
        // Handle invalid JSON, e.g., remove the invalid tag
        console.error(`Invalid JSON for tag: ${tag}`, error);
        return null; // or you can filter out invalid tags later
      }
    }).filter((tag) => tag !== null); // Remove invalid tags (optional)
    return (
      <div className="my-datasets-component">
        <HeaderComponent />
        <SidebarComponent activeTab={SIDEBAR_TABS.myDataSet} />
        <div className="main">
          <section className="section-3">
            <Button
              variant="contained"
              className="back-to-main"
              onClick={() =>
                this.props.history.push(`${DOCUMENT_ROOT}my-datasets`)
              }
            >
              Back to Main
            </Button>
          </section>
          <section className="section-4">
            <div className="name-desc">
              <DatasetName className="dataset-name" style={{ padding: 0 }}>
                {/* <div className='heading'>MY DATASETS / DETAILS/</div> */}
                <CustomTooltip content={rootDataset.name}>
                  {trimDatasetName(rootDataset.name)}
                </CustomTooltip>
                <img
                  style={{
                    cursor: "pointer",
                    marginLeft: "357px",
                    marginTop: "11px",
                  }}
                  src={editImage}
                  //   onClick={() => this.onEditDataset(rootDataset.uid)}
                  onClick={() => this.toggleCollapse("dataseteditModal")}
                />
              </DatasetName>
              {/* <span className='version'>Version {childDataset.version}</span> */}
              <div className="version-box" onClick={this.toggleDropdown7}>
                <div className="version-text">
                  {" "}
                  Version{childDataset.version}
                </div>

                <img
                  className="close"
                  alt="Close"
                  src={closeIcon1}
                  // onClick={handleClose} // Correct onClick syntax
                />

                {this.state.showDropdown && (
                  <div className="dropdown-menu">
                    {this.state.versions.map((item) => (
                      <div key={item.version_uid} className="dropdown-item">
                        Version {item.version}
                      </div>
                    ))}
                  </div>
                )}
              </div>

              <button
                className="manage-version"
                onClick={this.toggleVersionModal}
                style={{ backgroundColor: "white" }}
              >
                {" "}
                Manage Versions{" "}
              </button>
            </div>
          </section>
          <section className="section-4-1">
            <section className="section-4">
              <div className="general-info">
                <div className="name-desc">
                  {/* <div className='dataset-name'>
                                <div className='heading'>MY DATASETS / DETAILS/</div>
                                <div className='name'>{rootDataset.name}</div>
                            </div> */}

                  <div className="description" style={{ marginTop: 0 }}>
                    <div className="heading">
                      DESCRIPTION
                      <img
                        style={{ cursor: "pointer" }}
                        src={editImage}
                        //   onClick={() => this.onEditDataset(rootDataset.uid)}
                        onClick={() =>
                          this.toggleCollapse("descriptioneditModal")
                        }
                      />
                    </div>

                    <div className="content">
                      <SafeHtmlComponent data={childDataset.description} />
                      {childDataset.description.length > 450 && (
                        <div
                          className="show-more"
                          onClick={() => this.toggleCollapse("desciptionModal")}
                        >
                          more...
                        </div>
                      )}
                    </div>
                  </div>
                  <div className="creator-name sh-dd">
                    <div className="heading">License</div>
                    <div className="name text-truncate">
                      {childDataset.license}
                    </div>
                    <button
                      onClick={this.toggleLicenseModal}
                      className="manage-license"
                    >
                      {" "}
                      Manage License{" "}
                    </button>
                  </div>

                  <div className="tags" style={{ marginTop: 0 }}>
                    <div className="heading">
                      Tags
                      <img
                        style={{ cursor: "pointer" }}
                        src={editImage}
                        //   onClick={() => this.onEditDataset(rootDataset.uid)}
                        onClick={() => this.toggleCollapse("tageditModal")}
                      />
                    </div>
                    <div className="tagging">
                      {visibleTags.map((tag, index) => {
                        try {
                          // Parse the tag string into a JSON object
                          const parsedTag = JSON.parse(tag.replace(/'/g, '"')); // Replace single quotes with double quotes for JSON parsing
                          const metadata = parsedTag.metadata;

                          // Format the tag string
                          const formattedTag = [
                            metadata?.Variable_Level_1,
                            metadata?.Term,
                            metadata?.Topic,
                            metadata?.Category,
                          ]
                            .filter(Boolean) // Remove undefined or null values
                            .join(" : ");

                          return (
                            <span key={index} className="tagged">
                              {formattedTag}
                            </span>
                          );
                        } catch (error) {
                          console.error(
                            "Error parsing or formatting tag:",
                            tag,
                            error
                          );
                          return (
                            <span key={index} className="tagged">
                              Invalid tag format
                            </span>
                          );
                        }
                      })}

                      {/* View More Button */}
                      {remainingTagsCount > 0 && (
                        <div
                          className="view-more-wrapper"
                          onMouseEnter={() => {
                            this.setState({ showAllTags: true });
                          }}
                          onMouseLeave={() => {
                            this.setState({ showAllTags: false });
                          }}
                        >
                          <button className="view-more-button">
                            +{remainingTagsCount} MORE
                          </button>

                          {/* Hoverable Modal */}
                          {this.state.showAllTags && (
                            <div className="hover-modal">
                              <div className="tag-heading">
                                Tags ({tags.length})
                              </div>
                              <div className="tag-container">
                                {tags.map((tag, index) => {
                                  try {
                                    const parsedTag = JSON.parse(
                                      tag.replace(/'/g, '"')
                                    );
                                    const metadata = parsedTag.metadata;

                                    const formattedTag = [
                                      metadata?.Variable_Level_1,
                                      metadata?.Term,
                                      metadata?.Topic,
                                      metadata?.Category,
                                    ]
                                      .filter(Boolean)
                                      .join(" : ");

                                    return (
                                      <div
                                        key={index}
                                        className="modal-tagged"
                                        style={{}}
                                      >
                                        {formattedTag}
                                      </div>
                                    );
                                  } catch (error) {
                                    console.error(
                                      "Error parsing or formatting tag:",
                                      tag,
                                      error
                                    );
                                    return (
                                      <div key={index} className="modal-tagged">
                                        Invalid tag format
                                      </div>
                                    );
                                  }
                                })}
                              </div>
                            </div>
                          )}
                        </div>
                      )}
                    </div>
                  </div>

                  {/* <div className='org sh-dd'>
                                        <div className='heading'>tags</div>
                                        <div className='name tags'>{childDataset.tags.map(item => <span key={item} className='tag'>{item}</span>)}</div>
                                    </div> */}
                </div>

                {/* <div className='owner'> */}
                {/* <div className='creator-name sh-dd'>
                                        <div className='heading'>dataset owner</div>
                                        <div className='name text-truncate'>{rootDataset.creator_name}</div>
                                    </div> */}
                {/* <div className='org-cr'> */}
                {/* <div className='org sh-dd'>
                                            <div className='heading'>organisation</div>
                                            <div className='name'> - </div>
                                        </div> */}
                {/* <div className='cr-date sh-dd'>
                                    <div className='heading'>created</div>
                                    <div className='name'>{formatDate(rootDataset.creation_date)}</div>
                                </div> */}
                {/* </div> */}
                {/* <div className='ver-up'>
                                        <div className='version sh-dd'>
                                    <div className='heading'>version</div>
                                    <div className='name'>{childDataset.version}</div>
                                </div>
                                        <div className='up-date sh-dd' style={{marginLeft: 0}}>
                                    <div className='heading'>updated</div>
                                    <div className='name'>{formatDate(rootDataset.last_modified_date)}</div>
                                </div>
                                    </div> */}
                {/* </div> */}

                <div className="dataset-info">
                  {/* <div className="org sh-dd">
                    <div className="heading">organisation</div>
                    <div className="name"> - </div>
                  </div> */}

                  <div className="size-rw-col">
                    <div className="size sh-dd">
                      <div className="heading">Created</div>
                      <div className="name" style={{ fontSize: "16px" }}>
                        {formatDate(childDataset.creation_date)}
                      </div>
                    </div>
                    <div className="rows sh-dd">
                      <div className="heading">Updated</div>
                      <div className="name">
                        {formatDate(childDataset.last_modified_date)}
                      </div>
                    </div>
                    <div className="columns sh-dd">
                      <div className="heading">Status</div>
                      <div
                        className="name"
                        style={{
                          fontSize:
                            childDataset.status.trim().toLowerCase() ===
                            "processing_failed"
                              ? "clamp(8px, 2vw, 8px)"
                              : "11px",
                        }}
                      >
                        {childDataset.status}
                      </div>
                    </div>
                  </div>
                  <div className="size-rw-col">
                    <div className="size sh-dd">
                      <div className="heading">size</div>
                      <div
                        className="name"
                        style={{
                          fontSize:
                            calculateMemorySize(childDataset.dataset_size)
                              .length > 8
                              ? "clamp(10px, 2vw, 16px)"
                              : "20px",
                          letterSpacing: "-1px",
                        }}
                      >
                        {calculateMemorySize(childDataset.dataset_size)}
                      </div>
                    </div>
                    <div className="rows sh-dd">
                      <div className="heading">rows</div>
                      <div
                        className="name"
                        style={{
                          fontSize: "21px",
                          letterSpacing: "-1px",
                        }}
                      >
                        {this.formatLargeNumber(
                          childDataset.dataset_records_count
                        )}
                      </div>
                    </div>
                    <div className="columns sh-dd">
                      <div className="heading">columns</div>
                      <div
                        className="name"
                        style={{ fontSize: "21px", letterSpacing: "-1px" }}
                      >
                        {childDataset.dataset_columns_count}
                      </div>
                    </div>
                  </div>
                  {/* <div className="size-rw-col">
                    <div className="cr-date sh-dd">
                      <div className="heading">created</div>
                      <div className="name">
                        {formatDate(childDataset.creation_date)}
                      </div>
                    </div>
                    <div className="up-date sh-dd">
                      <div className="heading">updated</div>
                      <div className="name">
                        {formatDate(childDataset.last_modified_date)}
                      </div>
                    </div>
                    <div className="up-date sh-dd">
                      <div className="heading">visibility</div>
                      <div className="name">{rootDataset.visibility}</div>
                    </div>
                  </div> */}

                  <div className="org sh-dd" style={{ height: "152px" }}>
                    <div className="heading123">
                      GEOSPATIAL VARIABLES
                      <img
                        style={{ cursor: "pointer" }}
                        src={editImage}
                        //   onClick={() => this.onEditDataset(rootDataset.uid)}
                        onClick={() =>
                          this.toggleCollapse("geospatialVariableEditModal")
                        }
                      />
                    </div>

                    <div className="unique">
                      <div className="heading-unique">
                        {" "}
                        Latitude
                        <div
                          style={{
                            fontSize: "clamp(12px, 2vw, 12px)",
                            textAlign: "end",
                          }}
                        >
                          {childDataset.latitude}
                        </div>
                      </div>

                      <div className="heading-unique2">
                        Longitude
                        <div
                          style={{
                            fontSize: "clamp(12px, 2vw, 12px)",
                            textAlign: "end",
                          }}
                        >
                          {childDataset.longitude}
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="org sh-dd">
                    <div className="heading">ORGANIZATION</div>
                    <div className="name"> - </div>
                  </div>

                  <div className="org sh-dd">
                    <div className="heading">
                      WEBSITE
                      <img
                        src={editImage}
                        //   onClick={() => this.onEditDataset(rootDataset.uid)}
                      />
                    </div>
                    <div className="name"> - </div>
                  </div>

                  <div className="org sh-dd">
                    <div className="heading">
                      ATTRIBUTION
                      <img
                        src={editImage}
                        //   onClick={() => this.onEditDataset(rootDataset.uid)}
                      />
                    </div>
                    <div className="name"> - </div>
                  </div>
                </div>
                <div className="dataset-access">
                  <div className="actions sh-dd">
                    <div className="handlers">
                      {/* {rootDataset.shared_on ? (
                        <img
                          src={ImgPencil}
                          style={{ opacity: "0.5", cursor: "not-allowed" }}
                        />
                      ) : (
                        <img
                          src={ImgPencil}
                          onClick={() => this.onEditDataset(rootDataset.uid)}
                        />
                      )} */}
                      <a href={childDataset.s3_url} target="_self">
                        {" "}
                        <img src={ImgDownload} title="Download" />{" "}
                      </a>
                      {/* {(rootDataset.is_shared || rootDataset?.latest_version?.status !== "PROCESSED") 
                            ? <img src={ImgShare} style={{opacity: '0.5', cursor: 'not-allowed'}} /> 
                            : <img src={ImgShare} title="Share" onClick={() => this.toggleCollapse('shareDataset')} />} */}
                      {rootDataset.is_shared ||
                      rootDataset?.latest_version?.status !== "PROCESSED" ? (
                        rootDataset?.latest_version?.status ===
                        "PROCESSING_FAILED" ? (
                          <img
                            src={ImgShare}
                            style={{ opacity: "0.5", cursor: "not-allowed" }}
                            title="Cannot share because the dataset could not be processed."
                          />
                        ) : (
                          <img
                            src={ImgShare}
                            style={{ opacity: "0.5", cursor: "not-allowed" }}
                          />
                        )
                      ) : (
                        <img
                          src={ImgShare}
                          title="Share"
                          onClick={() => this.toggleCollapse("shareDataset")}
                        />
                      )}
                      {rootDataset.shared_on ? (
                        <img
                          src={ImgDelete}
                          title="Delete Dataset"
                          style={{ opacity: "0.5", cursor: "not-allowed" }}
                        />
                      ) : (
                        <img
                          src={ImgDelete}
                          title="Delete Dataset"
                          onClick={() => this.toggleDeleteModal(rootDataset)}
                        />
                      )}
                    </div>
                  </div>

                  <div className="creator-name sh-dd">
                    <div className="heading">Visibility</div>
                    <div
                      className="name text-truncate"
                      style={{ marginBottom: "-42px" }}
                    >
                      {rootDataset.visibility}
                    </div>
                    <div
                      className="manage-license22"
                      //   style={{
                      //     height: "36px",
                      //     width: "180px",
                      //     fontSize: "14px",
                      //     marginLeft: "109px",
                      //     fontWeight: 500,
                      //     fontFamily: "Roboto",
                      //     marginTop: "4px",
                      //     padding: "6px 12px",
                      //   }}
                    >
                      Publish to Ocean of Data{" "}
                    </div>
                  </div>

                  {!rootDataset.shared_on &&
                  this.props.dataset.datasetSharedWithUsers.length > 0 ? (
                    <div
                      className="dataset-users sh-dd"
                      style={{
                        padding: "14px 0",
                        maxHeight: "233px",
                        minHeight: "72px",
                        // overflowY: "auto",
                        height: "152px",
                      }}
                    >
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "space-around",
                        }}
                      >
                        <div
                          style={{
                            color: "#172737",
                            fontSize: "12px",
                            fontFamily: "SpaceGrotesk",
                            fontStyle: "normal",
                            fontWeight: 500,
                            lineHeight: "16px",
                            textTransform: "uppercase",
                          }}
                        >
                          DATASET USERS
                        </div>
                        <div
                          style={{
                            border: "1px solid",
                            borderRadius: "30px",
                            borderColor: "#82a6d9",
                            color: "#82a6d9",
                            cursor: "pointer",
                            padding: "8px 18px",
                            fontFamily: "Roboto",
                            fontSize: "14px",
                            fontWeight: 500,
                            lineHeight: "20px",
                          }}
                          onClick={() =>
                            this.toggleCollapse("manageAccessModal")
                          }
                        >
                          Manage Access+{" "}
                        </div>
                      </div>

                      <div
                        style={{
                          overflowY: "auto",
                          padding: "0px",
                        }}
                      >
                        {this.props.dataset.datasetSharedWithUsers.map(
                          (item) => {
                            return (
                              <DatasetAccessComponent
                                key={item.uid}
                                data={item}
                                datasetId={rootDataset.uid}
                              />
                            );
                          }
                        )}
                      </div>
                      <div
                        style={{
                          color: "#82a6d9",
                          fontSize: "14px",
                          fontFamily: "Space Mono",
                          fontWeight: 400,
                          lineHeight: "20px",
                          // text-transform: capitalize;
                          cursor: "pointer",
                          lineHeight: "20px",
                          marginLeft: "16px",
                        }}
                      >
                        more...
                      </div>
                    </div>
                  ) : (
                    <div
                      className="dataset-users sh-dd"
                      style={{
                        height: "calc(77px * 3)",
                        flexDirection: "row",
                        gap: "8px",
                        fontSize: "14px",
                        height: "152px",
                      }}
                    >
                      <img src={ImgInfoSm} width="24px" height="24px" /> Dataset
                      is not shared with anyone yet.
                    </div>
                  )}
                  <div
                    className="dataset-users sh-dd"
                    style={{
                      height: "calc(77px * 3)",
                      flexDirection: "row",
                      gap: "8px",
                      fontSize: "14px",
                      height: "152px",
                      display: "flex",
                      flexDirection: "column",
                    }}
                  >
                    <div
                      style={{
                        fontFamily: "SpaceGrotesk",
                        fontSize: "12px",
                        fontWeight: "500",
                        opacity: "0.8",
                        color: "#1a1c1e",
                      }}
                    >
                      DATASET FILES
                      <img
                        style={{ marginLeft: "59%", cursor: "pointer" }}
                        src={editImage}
                        onClick={() => this.toggleCollapse("editDatasetFiles")}
                        //   onClick={() => this.onEditDataset(rootDataset.uid)}
                      />
                    </div>

                    <div
                      style={{
                        fontFamily: "Space Mono",
                        fontSize: "14px",
                        fontWeight: "400",
                        color: "#1a1c1e",
                        display: "flex",
                        justifyContent: "space-between",
                        lineHeight: "20px",
                      }}
                    >
                      {this.state.primaryFileBaseName}
                      <div>{this.state.primaryFileExtension}</div>
                    </div>

                    <div
                      style={{
                        fontFamily: "Space Mono",
                        fontSize: "14px",
                        fontWeight: "400",
                        color: "#1a1c1e",
                        display: "flex",
                        justifyContent: "space-between",
                        lineHeight: "20px",
                      }}
                    >
                      {secondaryFile && secondaryFile.length > 0 ? (
                        <ul>
                          {secondaryFile.slice(0, 1).map((file) => {
                            // Ensure file_name exists before processing
                            const fileParts = file.file_name
                              ? file.file_name.split(".")
                              : [];
                            const fileName =
                              fileParts.length > 1
                                ? fileParts.slice(0, -1).join(".")
                                : file.file_name || "Unknown";
                            const fileExtension =
                              fileParts.length > 1
                                ? fileParts.pop()
                                : "Unknown";

                            return (
                              <li key={file.uid}>
                                <div
                                  style={{
                                    display: "flex",
                                    justifyContent: "space-between",
                                    width: "296px",
                                  }}
                                >
                                  <div>{fileName}</div>
                                  <div>{fileExtension}</div>
                                </div>
                              </li>
                            );
                          })}
                        </ul>
                      ) : (
                        <p>No secondary files available.</p>
                      )}
                    </div>

                    <div
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                      }}
                    >
                      <div
                        style={{
                          color: "#82a6d9",
                          fontSize: "14px",
                          fontFamily: "Space Mono",
                          fontWeight: 400,
                          lineHeight: "20px",
                          // text-transform: capitalize;
                          cursor: "pointer",
                          lineHeight: "20px",
                          marginTop: "-5px",
                        }}
                        onClick={() => this.toggleCollapse("viewDatasetFiles")}
                      >
                        more...
                      </div>
                    </div>
                  </div>

                  <div className="org sh-dd">
                    <div className="heading">DATASET TYPE</div>
                    <div className="name"> {childDataset.dataset_type} </div>
                  </div>
                  <Modal
                    style={{
                      width: "349px",
                      top: "24px",
                      left: "20px",
                      padding: "16px",
                    }}
                    isOpen={this.state.isDeleteModalOpen}
                    onRequestClose={this.closeDeleteModal}
                    size={"md"}
                    className={"share-dataset-component"}
                  >
                    <ModalHeader
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "center",
                        height: "32px",
                      }}
                    >
                      <span
                        style={{
                          flex: "2",
                          fontSize: "11px",
                          fontWeight: 500,
                          lineHeight: "16px",
                        }}
                      >
                        Delete Confirmation
                      </span>
                      {/* <RxCross1 style={{ cursor: 'pointer', marginLeft: '210px', color: 'grey', fontSize:'20px' }} onClick={this.closeDeleteModal} onMouseOver={(e) => { e.target.style.color = 'black'; }} onMouseOut={(e) => { e.target.style.color = 'grey'; }} /> */}
                    </ModalHeader>
                    <ModalBody>
                      <div
                        className="gen-info"
                        style={{
                          width: "100%",
                          fontSize: "16px",
                          lineHeight: "24px",
                          wordWrap: "break-word",
                          color: "#1A1C1E",
                        }}
                      >
                        Are you sure you want to delete the selected dataset?
                      </div>
                      <div
                        style={{
                          fontSize: "11px",
                          lineHeight: "24px",
                          color: "#93000A",
                          fontWeight: 500,
                          lineHeight: "16px",
                          height: "16px",
                        }}
                      >
                        This action cannot be undone.
                      </div>
                    </ModalBody>
                    <ModalFooter style={{ padding: "10px" }}>
                      <HgButtonComponent
                        buttonText={"CANCEL"}
                        isPrimary={false}
                        onclickHandler={this.closeDeleteModal}
                      />
                      <HgButtonComponent
                        buttonText={"DELETE"}
                        isPrimary={true}
                        onclickHandler={this.handleDeleteDataset}
                        type="submit"
                      />
                    </ModalFooter>
                  </Modal>
                </div>
              </div>
              <section className="section-5">
                <div className="ds-name">
                  {`${childDataset.name} (columns ${childDataset.dataset_columns_count})`}{" "}
                  <img
                    src={ImgPreviewSmall}
                    onClick={() => this.toggleCollapse("columnDetails")}
                  />
                </div>
                {this.state.isPreviewLoading ? (
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      gap: "16px",
                    }}
                  >
                    Dataset Preview Loading <Loader />
                  </div>
                ) : (
                  <div className="table-container">
                    <DatasetPreviewComponent
                      preview_table_data={this.state.preview_table_data}
                      preview_table_header={this.state.preview_table_header}
                    />
                  </div>
                )}
              </section>
            </section>
          </section>
        </div>
        <DatasetVersionsComponent
          data={this.props.dataset.childDatasets}
          toggle={() => this.toggleVersionsPopup()}
          modal={this.state.versionModal}
          onAccept={this.getVersionInfo}
        />

        <ShareDatasetComponent
          rootDatasetId={rootDataset.uid}
          toggle={() => this.toggleCollapse("shareDataset")}
          modal={this.state.shareDatasetModal}
          onAccept={() => this.toggleCollapse("shareDataset")}
          onSubmit={this.onShareDataset}
        />
        <DatasetColumnDetailsPopComponent
          datasetSchema={this.state.datasetSchema}
          toggle={() => this.toggleCollapse("columnDetails")}
          modal={this.state.columnDetailsModal}
          onAccept={() => this.toggleCollapse("columnDetails")}
        />
        <DatasetDescriptionPopupComponent
          name={rootDataset.name}
          version={childDataset.version}
          description={childDataset.description}
          toggle={() => this.toggleCollapse("desciptionModal")}
          toggle1={() => this.toggleCollapse("descriptioneditModal")}
          modal={this.state.desciptionModal}
          onAccept={() => this.toggleCollapse("desciptionModal")}
        />
        <ViewLicensePopupComponent
          name={rootDataset.name}
          licenseOpen={this.state.licenseOpen}
          toggleLicenseModal={this.toggleLicenseModal}
          dataset_root_uid={rootDataset.uid}
          dataset_child_uid={childDataset.uid}
        />
        <ViewVersionsPopupComponent
          versionOpen={this.state.versionOpen}
          toggleVersionModal={this.toggleVersionModal}
        />
        <DatasetDescriptionEditPopupComponent
          name={rootDataset.name}
          version={childDataset.version}
          description={childDataset.description}
          dataset_root_uid={rootDataset.uid}
          dataset_child_uid={childDataset.uid}
          toggle={() => this.toggleCollapse("descriptioneditModal")}
          modal={this.state.descriptioneditModal}
          onAccept={() => this.toggleCollapse("descriptioneditModal")}
          onSave={this.handleSave}
        />

        <DatasetNameEditPopupComponent
          name={rootDataset.name}
          version={childDataset.version}
          description={childDataset.description}
          dataset_root_uid={rootDataset.uid}
          dataset_child_uid={childDataset.uid}
          toggle={() => this.toggleCollapse("dataseteditModal")}
          modal={this.state.dataseteditModal}
          onAccept={() => this.toggleCollapse("dataseteditModal")}
          onSave={this.handleSave}
          creation={childDataset.creation_date}
        />

        <TagEditPopupComponent
          name={rootDataset.name}
          version={childDataset.version}
          tags={rootDataset.tags}
          toggle={() => this.toggleCollapse("tageditModal")}
          modal={this.state.tageditModal}
          onAccept={() => this.toggleCollapse("tageditModal")}
          existingTags={existingTags}
          dataset_root_uid={rootDataset.uid}
          dataset_child_uid={childDataset.uid}
        />
        <ManageAccessPopupComponent
          toggle={() => this.toggleCollapse("manageAccessModal")}
          modal={this.state.manageAccessModal}
          onAccept={() => this.toggleCollapse("manageAccessModal")}
          sharedUsers={sharedUsers}
          rootDataset={rootDataset}
        />
        <EditDatasetFilesPopupComponent
          rootDataset={rootDataset}
          childDataset={childDataset}
          shareUsers={sharedUsers}
          toggle={() => this.toggleCollapse("editDatasetFiles")}
          modal={this.state.editDatasetFiles}
          childDataset_uid={childDataset.uid}
          secondary_file={secondaryFile}
        />

        <ViewDatasetFilesPopupComponent
          rootDataset={rootDataset}
          childDataset={childDataset}
          shareUsers={sharedUsers}
          toggle={() => this.toggleCollapse("viewDatasetFiles")}
          modal={this.state.viewDatasetFiles}
          childDataset_uid={childDataset.uid}
          secondary_file={secondaryFile}
        />

        <GeospatialVariableEditComponent
          name={rootDataset.name}
          datasetSchema={this.state.datasetSchema}
          version={childDataset.version}
          latitude={childDataset.latitude}
          longitude={childDataset.longitude}
          dataset_root_uid={rootDataset.uid}
          dataset_child_uid={childDataset.uid}
          toggle={() => this.toggleCollapse("geospatialVariableEditModal")}
          modal={this.state.geospatialVariableEditModal}
          onAccept={() => this.toggleCollapse("geospatialVariableEditModal")}
          onSave={this.handleSave1}
        />
      </div>
    );
  }
}

function mapStateToProps(state) {
  const {
    isDatasetsListLoading,
    datasetInfo,
    childDatasets,
    datasetSharedWithUsers,
  } = state.dataset;
  return {
    dataset: {
      isDatasetsListLoading,
      datasetInfo,
      childDatasets,
      datasetSharedWithUsers,
    },
  };
}

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators(datasetActions, dispatch),
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ViewDatasetsComponent);
