import React, { useState, useEffect, useRef } from "react";
import axios from "axios";
import { API_URL } from "../../config";
import SessionUtils from '../../utils/sessionUtils';
import { Button, Modal, ModalBody, ModalHeader, ModalFooter } from "reactstrap";
import "../../styles/editDatasetFilesPopupComponent.scss";
import FileComponent from "./fileComponent";
import ViewAdditionalFileComponent from "./viewAdditionalFileComponent";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import HgButtonComponent from "./hgButtonComponent";
import Switch from "@mui/material/Switch";
import { getFileFormat } from "../../utils/utils";
import DatasetApi from "../../api/datasetApi";

const ViewDatasetFilesPopupComponent = (props) => {
  const { modal, toggle, rootDataset, childDataset, sharedUsers} = props;

  const [showPopup, setShowPopup] = useState(false);
  const [selectedFile, setSelectedFile] = useState(null);
  const [additionalUrl, setAdditionalUrl] = useState("");


  const primaryFileName  = childDataset?.file_name?.primary_name?.s3_file_name


  
  return (
    <div className="edit-dataset-files-component">
      <Modal
        isOpen={modal}
        toggle={toggle}
        size={"md"}
        className="edit-dataset-files-component"
        style={{ position: "relative" }}
      >
        <ModalHeader toggle={toggle}>Dataset Files</ModalHeader>
        <ModalBody>
          <div className="dataset-name">
            <div className="heading">Dataset Title</div>
            <div className="detail">{rootDataset.name}</div>
          </div>
          <div className="dataset-version">
            <div className="heading">Dataset Version</div>
            <div className="detail">{childDataset.version}</div>
          </div>
          <div className="primary">PRIMARY FILE</div>
          <FileComponent
            download={childDataset.s3_url}
            is_shared={rootDataset.is_shared}
            shared_users={sharedUsers}
            setShowPopup={setShowPopup}
            FileName={primaryFileName}
          />
          {/* <div className="supporting-files">SUPPORTING FILES</div>
          <div className="file-type">
            <div className="supporting-file-type">SUPPORTING FILE TYPE</div>
            <div className="dropdown">
              <span>.file</span>
              <ArrowDropDownIcon />
            </div>
          </div>
          <Button className="upload-file" style={{ cursor: "pointer" }}>
            UPLOAD FILE
          </Button>
          <FileComponent
            download={childDataset.s3_url}
            is_shared={rootDataset.is_shared}
            shared_users={sharedUsers}
            setShowPopup={setShowPopup}
          /> */}
          <div className="additional-files">ADDITIONAL FILES</div>
          <ViewAdditionalFileComponent
            // download={childDataset.file_name.secondary_name.file_url}
            is_shared={rootDataset.is_shared}
            shared_users={sharedUsers}
            setShowPopup={setShowPopup}
            secondary={childDataset}
            
          />
        </ModalBody>
      </Modal>
    </div>
  );
};

export default ViewDatasetFilesPopupComponent;
