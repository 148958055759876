import React, { useRef, useState } from "react";
import { Modal, ModalBody, ModalFooter, ModalHeader } from "reactstrap";
import HgButtonComponent from "./hgButtonComponent";
import "../../styles/fileComponent.scss";
import fileImage from "../../images/FileLogo.png";
import { RiDeleteBin6Line } from "react-icons/ri";
import AuthorizationPopupComponent from "./authorizationPopupComponent";
import axios from "axios";
import sessionUtils from '../../utils/sessionUtils';
const { REACT_APP_API_URL } = process.env;
export const API_URL = REACT_APP_API_URL;




const ViewAdditionalFileComponent = (props) => {
  const { download, is_shared, shared_users, secondary } = props;
  const manageAccess = shared_users;
  const fileInputRef = useRef();


  const [showPopup, setShowPopup] = useState(false);
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  const [fileToDelete, setFileToDelete] = useState(null);
  const secondary_file  = secondary?.file_name?.secondary_name

  const [files, setFiles] = useState(secondary_file || []);

//component to render the additional files

  const handleAuthorization = () => {
    if (is_shared && !manageAccess) {
      setShowPopup(true);
      console.log(showPopup);
      return;
    }
    triggerFilePicker();
  };

  const triggerFilePicker = () => {
    fileInputRef.current.click();
  };


  //function for deleting the selected dataset
  const handleDeleteAccess = (uid) => {
    if (is_shared && !manageAccess) {
      setShowPopup(true);
      return;
    }
    
  const token = sessionUtils.getToken("access_token");
  axios
    .delete(`${API_URL}/auth/v1/remove-additional-file/`, {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
      data: { data_uid: uid }, // Send the file's unique ID
    })
    .then((response) => {
      if (response.status === 200) {
        // Update state to remove deleted file
        setFiles((prevFiles) => prevFiles.filter((file) => file.uid !== uid));
        setIsDeleteModalOpen(false);
      setFileToDelete(null); // Clear the selected file
      } else {
        alert("Failed to delete file. Please try again.");
      }
    })
    .catch((error) => {
      console.error("Error deleting file:", error);
      alert("An error occurred. Please try again.");
    });
  };

  return (
    <div style={{flexDirection: 'column',
        height: 'auto', borderBottom:'none'}} className="file-component">
      {files && files.length > 0 ? (
        files.map((file, index) => (
          <div key={index} style={{ display: 'flex',
            alignItems: 'center',
            marginBottom: '20px',
            borderBottom: '1px solid #ddd',
            paddingBottom: '10px'}} className="file-row">
            {/* Image for each file */}
            <img className="file-img" src={fileImage} alt="file" />
  
            {/* File name */}
            <div style={{flexGrow: 1, marginLeft:'20px'
            }} className="file-name">{file.file_name}</div>
  
            {/* Download button */}
            <a className="download-file" href={file.file_url} target="_self">
              Download
            </a>
  
            {/* Delete button */}
            {/* <div className="delete-file">
              {!(is_shared && !manageAccess) && (
                <div
                  style={{
                    cursor: "pointer", // The icon is only rendered when clickable, so "pointer" is always fine here
                  }}
                  onClick={() => confirmDelete(file)} // Pass file uid or other unique identifier for delete
                >
                  <RiDeleteBin6Line />
                </div>
              )}
            </div> */}
          </div>
        ))
      ) : (
        <p>No secondary files available.</p>
      )}
  
      {/* Other components, e.g., file input or authorization popup */}
      <input type="file" ref={fileInputRef} style={{ display: "none" }} />
  
      {showPopup && (
        <AuthorizationPopupComponent
          showPopup={showPopup}
          onClose={() => setShowPopup(false)}
        />
      )}

    </div>
  );
  
};

export default ViewAdditionalFileComponent;
