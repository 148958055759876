import React, { useEffect, useState } from "react";
import DataCategoryGradientComponent from "./pure/dataCategoryGradientComponent";
import SearchBarComponent from "./pure/searchBarComponent";
import { ImgTBBCLogo } from "../images";
import { GoChevronRight } from "react-icons/go";
import { GoPlus } from "react-icons/go";
import "../styles/oceanData.scss";
import GridViewDatasetComponent from "./pure/gridViewDatasetComponent";
import SelectComponent from "./pure/selectComponent";
import { OCEAN_DATA_FILTERS, SIDEBAR_TABS, VISIBILITY } from "../config";
import ListViewDatasetComponent from "./pure/listViewDatasetComponent";
import { BsGrid } from "react-icons/bs";
import { HiOutlineViewList } from "react-icons/hi";
import {
  getOceanDataset,
  getOceanFilterDataset,
  setOceanDataFilter,
} from "../actions/datasetActions";
import { useSelector, useDispatch } from "react-redux";
import Loader from "./loaderComponent";
import { Link } from "react-router-dom/cjs/react-router-dom";
import SidebarComponent from "./pure/sidebarComponent";
import { useHistory } from "react-router-dom/cjs/react-router-dom";
import PillButton from "./pure/pillButton";
import HeaderComponent from "./pure/headerComponent";

const OceanData = () => {
  const [isFilter, setIsFilter] = useState(false);
  const [isListView, setIsListView] = useState(false);
  const [filterCount, setfilterCount] = useState(0);
  const [searchText, setSearchText] = useState();
  const dispatch = useDispatch();
  const history = useHistory();

  const { oceanDatasets, isDatasetsListLoading, filterObj } = useSelector((state) => ({
    oceanDatasets: state.dataset.oceanDatasets,
    isDatasetsListLoading: state.dataset.isDatasetsListLoading,
    filterObj: state.dataset.oceanDataFilter,
  }));

  useEffect(() => {
    if (!Object.keys(filterObj).length) {
      dispatch(getOceanDataset());
      setfilterCount(0);
      return;
    }
  
    const paramObj = {};
    let count = 0;
    Object.keys(filterObj).forEach((filterKey) => {
      if (filterObj[filterKey].length > 0) {
        paramObj[filterKey] = filterObj[filterKey].join(",");
        count += filterObj[filterKey].length;
      }
    });
  
    setfilterCount(count);
    dispatch(getOceanFilterDataset(paramObj));
  }, [filterObj]); 

  const handleSearch = (searchText) => {
    const updatedFilterObj = {
      ...filterObj,
      ...(searchText
        ? { search_keywords: [searchText] }
        : { search_keywords: [] }),
    };
    dispatch(setOceanDataFilter(updatedFilterObj));
  };

  const handleRemoveFilter = (filter, lable) => {
    const filterValue = filterObj[filter].filter((value) => value !== lable);
    dispatch(setOceanDataFilter({ ...filterObj, [filter]: [...filterValue] }));
  };

  const handleAllDatasets = () => {
    history.push("/view-all-datasets");
  }

  return (
    <div className="ocean-of-data">
      {/* <Link to={"/"}>
        <div className="header" style={{ paddingLeft: "128px" }}>
          <img className="big-blue-cloud" src={ImgTBBCLogo} alt="LOGO" />
        </div>
      </Link> */}
      <HeaderComponent />
      <SidebarComponent activeTab={SIDEBAR_TABS.oceanDatasets} />
      <div className="main">
        <div className="ocean-data">Ocean of Data</div>
        <DataCategoryGradientComponent />
        <div
          style={{
            position: "relative",
            display: "flex",
            justifyContent: "space-between",
            alignItems: "flex-end",
          }}
        >
          <div
            style={{
              display: "flex",
              alignItems: "baseline",
              paddingTop: "30px",
            }}
          >
            <button
              style={{
                background: `${filterCount > 0 ? "#ADCBEC" : "none"}`,
                border: `${filterCount > 0 ? "none" : "1px solid #72777f"}`,
                fontWeight: "400",
              }}
              className="filter-btn"
              onClick={() => setIsFilter(true)}
            >
              <GoPlus /> Filters {filterCount > 0 ? `(${filterCount})` : ""}
            </button>
            {isFilter && (
              <>
                <div className="backdrop">
                  <div className="filter-popup">
                    <SelectComponent
                      setIsFilter={setIsFilter}
                      // setfilterCount={setfilterCount}
                    />
                  </div>
                </div>
              </>
            )}

            <SearchBarComponent searchText={filterObj.search_keywords?.join(",") || ""}  setSearchText={handleSearch} />
          </div>
          <div style={{ display: "flex", marginBottom: "18px" }}>
            <BsGrid
              onClick={() => setIsListView(false)}
              size={25}
              style={{
                color: `${!isListView ? "#004A75" : "#D5E4F6"}`,
                cursor: "pointer",
                marginRight: "15px",
              }}
            />
            <HiOutlineViewList
              style={{
                color: `${isListView ? "#004A75" : "#D5E4F6"}`,
                cursor: "pointer",
              }}
              onClick={() => setIsListView(true)}
              size={25}
            />
          </div>
        </div>
        <div className="active_filter">
          {Object.keys(filterObj).filter((filter) => filter!== "search_keywords").map((filter) => {
            return filterObj[filter].map((label, index) => (
              <PillButton
                keyword={label}
                removeKeyword={() => handleRemoveFilter(filter, label)}
              />
            ));
          })}
        </div>
        <div className="datasets">
          <div className="heading">Popular Datasets</div>
          <div className="view-all">
            <div className="view-all-text">View All</div>
            <div className="next-icon">
              <GoChevronRight />
            </div>
          </div>
        </div>
        {!isListView ? (
          <div className="popular-datasets-grid">
            <GridViewDatasetComponent />
            <GridViewDatasetComponent />
            <GridViewDatasetComponent />
            <GridViewDatasetComponent />
          </div>
        ) : (
          <div className="popular-datasets-list">
            <ListViewDatasetComponent />
            <ListViewDatasetComponent />
            <ListViewDatasetComponent />
          </div>
        )}
        {!isListView && <div className="seperation-line"></div>}
        <div className="datasets">
          <div className="heading">All Datasets</div>
          <div className="view-all" onClick={handleAllDatasets} style={{cursor: "pointer"}}>
            <div className="view-all-text">View All</div>
            <div className="next-icon">
              <GoChevronRight />
            </div>
          </div>
        </div>
        {isDatasetsListLoading ? (
          <Loader />
        ) : (
          <>
            {!isListView ? (
              <div className="all-datasets-grid">
                {oceanDatasets.results.map((item) => (
                  <GridViewDatasetComponent
                    key={item.uid}
                    title={item.name}
                    description={item.description}
                    payment={item.payment}
                    license={item.license}
                    dataset_format={item.dataset_format}
                    data_type={item.data_type}
                  />
                ))}
              </div>
            ) : (
              <div className="all-datasets-list">
                {oceanDatasets.results.map((item) => (
                  <ListViewDatasetComponent
                    key={item.uid}
                    title={item.name}
                    description={item.description}
                    payment={item.payment}
                    license={item.license}
                    data_type={item.data_type}
                  />
                ))}
              </div>
            )}
          </>
        )}
        {!isListView && !isDatasetsListLoading && (
          <div className="seperation-line"></div>
        )}
      </div>
    </div>
  );
};

export default OceanData;
