import React, { useState } from "react";
import { useSelector } from "react-redux";
import { Link, useHistory } from "react-router-dom";
import { DOCUMENT_ROOT, STORAGE_KEY } from "../../config";
import { DummyImgUser, ImgOMTLogo, ImgTBBCLogo, Logo } from "../../images";
import "../../styles/headerComponent.scss";
import {
  localStorageGetObject,
  localStorageSetObject,
} from "../../utils/localStorageUtils";
import SessionUtils from "../../utils/sessionUtils";

const HeaderComponent = (props) => {
  const history = useHistory();
  const [isDropdownOpen, setDropdownOpen] = useState(false);
  // const userInfo = useSelector(state => state.account.userInfo);
  const userInfo = localStorageGetObject(STORAGE_KEY.user_info);
  if (!userInfo) {
    return (
      <div className="dsg-header">
        <div className="logo">
          <Link to="/">
            <img src={ImgTBBCLogo} alt="LOGO" />
          </Link>
        </div>
      </div>
    );
  }
  if (Object.keys(userInfo).length !== 0) {
    userInfo.fullname = `${userInfo.first_name} ${userInfo.last_name}`;
  }

  const onLogout = () => {
    SessionUtils.flushToken(STORAGE_KEY.access_token);
    SessionUtils.flushToken(STORAGE_KEY.refresh_token);
    localStorageSetObject(STORAGE_KEY.user_info);
    setDropdownOpen(false);
    history.replace(DOCUMENT_ROOT);
  };

  const toggleDropdown = (e, status) => {
    e.stopPropagation();
    setDropdownOpen(status);
  };

  return (
    <>
      <div className="dsg-header">
        <div className="logo">
          <Link to="/">
            <img src={ImgTBBCLogo} alt="LOGO" />
          </Link>
        </div>
        {Object.keys(userInfo).length !== 0 && (
          <div
            className="user-profile-pic"
            onClick={(e) => {
              toggleDropdown(e, true);
            }}
          >
            {userInfo.profile_pic ? (
              <img
                src={`data:image/jpg;base64, ${userInfo.profile_pic.slice(
                  2,
                  userInfo.profile_pic.length - 1
                )}`}
                style={{ cursor: "pointer" }}
              />
            ) : (
              <div className="profile-pic">{userInfo.fullname[0]}</div>
            )}
            <div className="user-info">
              <div className="username">{userInfo.fullname}</div>
              <div className="logout" onClick={onLogout}>
                log out
              </div>
              {/* <div className="user-email">{userInfo.email}</div> */}
            </div>
            {isDropdownOpen && (
              <div
                className="dropdown-toggler"
                onClick={(e) => toggleDropdown(e, false)}
              ></div>
            )}
            {isDropdownOpen && (
              <div className="dropdown-list">
                <ul>
                  <li onClick={() => history.push("/manage-account")}>
                    Profile
                  </li>
                  <li onClick={() => history.push("/account-settings")}>
                    Account Settings
                  </li>
                  {/* <li onClick={() => history.push('/settings')}>Account Settings</li> */}
                  {/* <li className="logout" onClick={onLogout}>Log Out</li> */}
                </ul>
                {/* <img src={ImgMenuDropDownPointer} /> */}
              </div>
            )}
          </div>
        )}
      </div>
    </>
  );
};

export default HeaderComponent;
