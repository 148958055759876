import React, { useRef, useState } from "react";
import "../../styles/fileComponent.scss";
import fileImage from "../../images/FileLogo.png";
import { RiDeleteBin6Line } from "react-icons/ri";
import AuthorizationPopupComponent from "./authorizationPopupComponent";

const FileComponent = (props) => {
  const { download, is_shared, shared_users, FileName } = props;
  const manageAccess = shared_users;
  const fileInputRef = useRef();

  const [showPopup, setShowPopup] = useState(false);

  //component to show the primary file

  const handleAuthorization = () => {
    if (is_shared && !manageAccess) {
      setShowPopup(true);
      console.log(showPopup);
      return;
    }
    triggerFilePicker();
  };

  const triggerFilePicker = () => {
    fileInputRef.current.click();
  };

  const handleDeleteAccess = () => {
    if (is_shared && !manageAccess) {
      setShowPopup(true);
      return;
    }
  };
  return (
    <div className="file-component">
      <img className="file-img" src={fileImage} />
      <div className="primary-file-name">{FileName}</div>
      <a  style={{marginLeft:'179px'}} className="download-file" href={download} target="_self">
        Download
      </a>
      <input type="file" ref={fileInputRef} style={{ display: "none" }} />

      {showPopup && (
        <AuthorizationPopupComponent
          showPopup={showPopup}
          onClose={() => setShowPopup(false)}
        />
      )}
    </div>
  );
};

export default FileComponent;
