import React, { useState, useEffect, useRef } from "react";
import axios from "axios";
import { API_URL } from "../../config";
import SessionUtils from '../../utils/sessionUtils';
import { Button, Modal, ModalBody, ModalHeader, ModalFooter } from "reactstrap";
import "../../styles/editDatasetFilesPopupComponent.scss";
import FileComponent from "./fileComponent";
import AdditionalFileComponent from "./additionalFileComponent";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import HgButtonComponent from "./hgButtonComponent";
import Switch from "@mui/material/Switch";
import { getFileFormat } from "../../utils/utils";
import DatasetApi from "../../api/datasetApi";

const EditDatasetFilesPopupComponent = (props) => {
  const { modal, toggle, rootDataset, childDataset, sharedUsers} = props;

  const [showPopup, setShowPopup] = useState(false);
  const [selectedFile, setSelectedFile] = useState(null);
  const [additionalUrl, setAdditionalUrl] = useState("");


  const primaryFileName  = childDataset?.file_name?.primary_name?.s3_file_name

  const handleUpdateClick = () => {
    uploadTextRef.current.click();
  };

  const uploadTextRef = useRef();

  const handleFileChange = (event) => {
    // setSelectedFile(event.target.files[0]);
    const file = event.target.files[0];
    if (!file) return; // Ensure a file is selected
    setSelectedFile(file); // Update state for selected file
    const fileExtension = getFileFormat(file.name);
    //then make data
    const CancelToken = axios.CancelToken;
    const source = CancelToken.source();
    const options = {
      headers: {
        "Content-Type": file.type,
      },
      cancelToken: source.token,
    };
    DatasetApi.getPresignedUrlToUpload(fileExtension).then(({ data }) => {
      const { presigned_url, dataset_S3_url } = data;

      axios.put(presigned_url, selectedFile, options).then((resp) => {
        if ((resp.status = "200")) {
          setAdditionalUrl(dataset_S3_url);
        }
      });
    });
  };

  const updatedData = {
    child_dataset_id: childDataset.uid,
    additional_url: additionalUrl,
    additional_file_name: selectedFile?.name
  };

  const handleSave = () => {

    console.log("hitting api");
    if (!selectedFile || !additionalUrl) {
      console.error("File or URL missing. Cannot save.");
      return;
    }
    
    //bring the file url
    const token = SessionUtils.getToken("access_token");
    const headers = {
      Authorization: `Bearer ${token}`, // Replace with your actual token
      "Content-Type": "application/json",
    };
    //then send data to api call
    try {
      axios
        .put(`${API_URL}/auth/v1/add-dataset-file/`, updatedData, {
          headers,
        })
        .then(() => {
          props.toggle("editDatasetFiles");
          setSelectedFile(null) 
        });
    } catch (error) {
      console.log(error);
    }
  };

  
  return (
    <div className="edit-dataset-files-component">
      <Modal
        isOpen={modal}
        toggle={toggle}
        size={"md"}
        className="edit-dataset-files-component"
        style={{ position: "relative" }}
      >
        <ModalHeader toggle={toggle}>Dataset Files</ModalHeader>
        <ModalBody>
          <div className="dataset-name">
            <div className="heading">Dataset Title</div>
            <div className="detail">{rootDataset.name}</div>
          </div>
          <div className="dataset-version">
            <div className="heading">Dataset Version</div>
            <div className="detail">{childDataset.version}</div>
          </div>
          <div className="primary">PRIMARY FILE</div>
          <FileComponent
            download={childDataset.s3_url}
            is_shared={rootDataset.is_shared}
            shared_users={sharedUsers}
            setShowPopup={setShowPopup}
            FileName={primaryFileName}
          />
          {/* <div className="supporting-files">SUPPORTING FILES</div>
          <div className="file-type">
            <div className="supporting-file-type">SUPPORTING FILE TYPE</div>
            <div className="dropdown">
              <span>.file</span>
              <ArrowDropDownIcon />
            </div>
          </div>
          <Button className="upload-file" style={{ cursor: "pointer" }}>
            UPLOAD FILE
          </Button>
          <FileComponent
            download={childDataset.s3_url}
            is_shared={rootDataset.is_shared}
            shared_users={sharedUsers}
            setShowPopup={setShowPopup}
          /> */}
          <div className="additional-files">ADDITIONAL FILES</div>
          <Button  onClick={handleUpdateClick} className="upload-file" style={{ cursor: "pointer" }}>
            UPLOAD FILE
          </Button>
          <div style={{display: 'flex', marginTop: '15px', fontFamily:"SpaceGrotesk", fontSize: '14px', fontWeight: 500, lineHeight:'20px',color:'#51606f'}}>
            FILE NAME :
          <div style={{display: 'flex', fontFamily:"SpaceGrotesk", fontSize: '14px', fontWeight: 500, lineHeight:'20px',color:'#00639a'}}>
          {selectedFile ? selectedFile.name : "No file selected"}
          </div>
          </div>
          <input
              type="file"
              ref={uploadTextRef}
              id="fileInput"
              onChange={handleFileChange}
              hidden
            />
          <AdditionalFileComponent
            // download={childDataset.file_name.secondary_name.file_url}
            is_shared={rootDataset.is_shared}
            shared_users={sharedUsers}
            setShowPopup={setShowPopup}
            secondary={childDataset}
            
          />
        </ModalBody>
        <ModalFooter>
          <div className="footer">
            <Switch />
            <label>Apply changes to all versions</label>
            <HgButtonComponent onclickHandler={() => handleSave()} buttonText={"Save"} isPrimary={true} />
          </div>
        </ModalFooter>
      </Modal>
    </div>
  );
};

export default EditDatasetFilesPopupComponent;
