import { PUBLISH_ACTIONS } from "../actions/actionTypes";

const initialState = {
  isLoading: false,
  isError: "",
  status: "",
  toBePublishDataset: {},
  publishedDatasets: {},
  publishedDatasetDetails: {},
};

// set the state on the basis of action it recieved
export default function publishReducer(state = initialState, action) {
  switch (action.type) {
    case PUBLISH_ACTIONS.PUBLISH_DATASET_LOADING:
      return { ...state, isLoading: true };
    case PUBLISH_ACTIONS.PUBLISH_DATASET_ADD:
      // checking if obj has then push the new data into results array
      if (Object.keys(state.toBePublishDataset).length) {
        return {
          ...state,
          isLoading: false,
          toBePublishDataset: {
            ...action.payload,
            results: [
              ...state?.toBePublishDataset?.results,
              ...action.payload.results,
            ],
          },
        };
      }
      return {
        ...state,
        isLoading: false,
        toBePublishDataset: { ...action.payload },
      };
    case PUBLISH_ACTIONS.PUBLISH_DATASET_ERROR:
      return { ...state, isLoading: false, isError: action.payload };
    case PUBLISH_ACTIONS.PUBLISH_DATASET_UPLOAD_LOADING:
      return { ...state, isLoading: true, status: "" };
    case PUBLISH_ACTIONS.PUBLISH_DATASET_UPLOAD:
      return { ...state, isLoading: false, status: action.payload.status };
    case PUBLISH_ACTIONS.PUBLISH_DATASET_UPLOAD_ERROR:
      return { ...state, isLoading: false, isError: action.payload };
    case PUBLISH_ACTIONS.DATASET_LIST_LOADING:
      return { ...state, isLoading: true};
    case PUBLISH_ACTIONS.DATASET_LIST_SUCCESS:
      return {
        ...state,
        isLoading: false,
        publishedDatasets: { ...action.payload },
      }
    case PUBLISH_ACTIONS.DATASET_LIST_ERROR:
      return { ...state, isLoading: false};
    case PUBLISH_ACTIONS.PUBLISH_DATASET_DETAILS_LOADING:
      return { ...state, isLoading: true};
    case PUBLISH_ACTIONS.PUBLISH_DATASET_DETAILS_SUCCESS:
      console.log(action.payload);
      return {
        ...state,
        isLoading: false,
        publishedDatasetDetails: { ...action.payload },
      }
    case PUBLISH_ACTIONS.PUBLISH_DATASET_DETAILS_ERROR:
      return { ...state, isLoading: false};
    case PUBLISH_ACTIONS.UPDATED_DESCRIPTION_LOADING:
      return { ...state, isLoading: true};
    case PUBLISH_ACTIONS.UPDATED_DESCRIPTION_SUCCESS:
      const updatedPublishedDatasetDetails = {
        ...state.publishedDatasetDetails,
        data: {
            ...state.publishedDatasetDetails.data,
            data: {
                ...state.publishedDatasetDetails.data.data,
                description: action.payload.description,
                documentation_link: action.payload.documentation_link,
            },
        },
    };
      return {
        ...state,
        isLoading: false,
        publishedDatasetDetails: updatedPublishedDatasetDetails
      }
    case PUBLISH_ACTIONS.UPDATED_DESCRIPTION_ERROR:
      return { ...state, isLoading: false};
    default:
      return state;
  }
}
